import React from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Select,
  Tabs,
  Checkbox,
  DatePicker,
  Space,
} from "antd";
import axios from "axios";
import { nanoid } from "nanoid";
import {
  Course,
  GroupSessionDTO,
  Instructor,
  Session,
  Student,
} from "../../types";
import os from "os";

type CreateGroupState = {
  allCourses: Array<Course> | [];
  allInstructors: Array<Instructor> | [];
  allSessions: Array<Session> | [];
  allStudents: Array<Student> | [];
  selectedCourse: Course | {};
  selectedStudents: Array<string> | [];
  groupName: string;
  groupUID: string;
  groupSessions: Array<GroupSessionDTO> | [];
};
const API = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;
export class CreateGroup extends React.Component<any, CreateGroupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      allCourses: [],
      allInstructors: [],
      allSessions: [],
      allStudents: [],
      selectedCourse: {},
      groupUID: nanoid(4),
      groupName: "",
      groupSessions: [],
      selectedStudents: [],
    };
  }
  componentDidMount() {
    axios.get(API + "/sessions").then((sessions) => {
      this.setState({ allSessions: sessions.data });
    });
    axios.get(API + "/courses").then((courses) => {
      this.setState({ allCourses: courses.data });
    });
    axios.get(API + "/students").then((students) => {
      this.setState({ allStudents: students.data });
    });
    axios.get(API + "/instructors").then((instructors) => {
      this.setState({ allInstructors: instructors.data });
    });
  }

  render() {
    return (
      <div>
        <Form
          name="basic"
          onFinish={(formData) => {
            let group = {
              name: this.state.groupName,
              students: formData.students.map(
                (studentId: string) =>
                  this.state.allStudents.filter(
                    (student) => student.id === studentId
                  )[0]
              ),
              course: this.state.allCourses[formData.course],
              groupSessions: this.state.groupSessions,
            };
            axios
              .post(API + "/groups", group)
              .then(() => {
                this.props.history.push("/groups");
              })
              .catch((err) => {
                console.log("inside error->", err);
              });
          }}
        >
          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Group name"
            name="name"
          >
            {console.log()}
            <Input value={this.state.groupName} disabled={true} />
          </Form.Item>

          <Form.Item
            name={"course"}
            label={"Group course"}
            rules={[
              {
                required: true,
                message: "Please enter group's course!",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onChange={(courseIndex: any) => {
                let name =
                  this.state.allCourses[courseIndex].name +
                  "." +
                  this.state.allCourses[courseIndex].level +
                  "." +
                  this.state.groupUID;

                this.setState({
                  selectedCourse: this.state.allCourses[courseIndex],
                  groupName: name,
                });
              }}
            >
              {/*@ts-ignore*/}
              {this.state.allCourses.map((course: Course, index: number) => {
                return (
                  <Select.Option key={course.id} value={index}>
                    {course.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name={"students"} label={"Students list"}>
            <Select
              mode={"multiple"}
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onChange={(selectedStudentsIds: any) => {
                /*let studentsList = this.state.selectedStudents;
                // @ts-ignore
                studentsList.push(e);*/
                let groupStudentsList = selectedStudentsIds.map(
                  (studentId: string) =>
                    this.state.allStudents.filter(
                      (student) => student.id === studentId
                    )[0]
                );
                this.setState({ selectedStudents: groupStudentsList });
              }}
            >
              {/*@ts-ignore*/}
              {this.state.allStudents.map((student: Student) => {
                return (
                  <Select.Option key={student.id} value={student.id}>
                    {student.studentName} {student.fatherName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <h2>Group sessions:</h2>
          <Button
            onClick={() => {
              let sessions = this.state.groupSessions;
              // @ts-ignore
              sessions.push({});
              // @ts-ignore
              this.setState({ groupSessions: sessions });
            }}
          >
            Add group session
          </Button>

          <Tabs defaultActiveKey="0">
            {console.log(
              "this.state.groupSessions--->",
              this.state.groupSessions
            )}
            {/*@ts-ignore*/}
            {this.state.groupSessions.map(
              (grSession: GroupSessionDTO, index: number) => {
                return (
                  <Tabs.TabPane tab={"Group session " + index} key={index}>
                    <div>
                      <label>Select instructor: </label>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        onSelect={(instructorIndex: any) => {
                          grSession.instructor = this.state.allInstructors[
                            instructorIndex
                          ];
                          let grSessions = this.state.groupSessions;
                          grSessions[index] = grSession;
                          // @ts-ignore
                          this.setState({ groupSessions: grSessions });
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {/*@ts-ignore*/}
                        {this.state.allInstructors.map(
                          (instructor: Instructor, index: number) => {
                            console.log();
                            return (
                              <Select.Option value={index}>
                                {instructor.name} {instructor.fatherName}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </div>

                    <div>
                      <label>Select Session: </label>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        onSelect={(sessionIndex: any) => {
                          grSession.session = this.state.allSessions[
                            sessionIndex
                          ];
                          let grSessions = this.state.groupSessions;
                          grSessions[index] = grSession;
                          // @ts-ignore
                          this.setState({ groupSessions: grSessions });
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {/*@ts-ignore*/}
                        {this.state.allSessions.map(
                          (session: Session, index: number) => {
                            return (
                              <Select.Option value={index}>
                                {session.name}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </div>
                    <div>
                      <Checkbox
                        onChange={(e) => {
                          grSession.isTaught = e.target.checked;
                          let grSessions = this.state.groupSessions;
                          grSessions[index] = grSession;
                          // @ts-ignore
                          this.setState({ groupSessions: grSessions });
                        }}
                      >
                        Is session taught
                      </Checkbox>
                    </div>

                    <div>
                      <label>Session date: </label>
                      <Space direction="vertical" size={12}>
                        <DatePicker
                          showTime
                          onOk={(moment) => {
                            grSession.date = moment.toDate();
                            let grSessions = this.state.groupSessions;
                            grSessions[index] = grSession;
                            // @ts-ignore
                            this.setState({ groupSessions: grSessions });
                          }}
                        />
                      </Space>
                    </div>
                    <div>
                      <label>Attended students: </label>
                      <Select
                        mode={"multiple"}
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(selectedStudentsIds: any) => {
                          grSession.attendedStudents = selectedStudentsIds.map(
                            (studentId: any) => {
                              return this.state.selectedStudents.filter(
                                (student: any) => student.id === studentId
                              )[0];
                              /*;*/
                            }
                          );

                          let grSessions = this.state.groupSessions;
                          grSessions[index] = grSession;
                          // @ts-ignore
                          this.setState({ groupSessions: grSessions });
                        }}
                      >
                        {/*@ts-ignore*/}
                        {this.state.selectedStudents.map((student: Student) => {
                          return (
                            <Select.Option key={student.id} value={student.id}>
                              {student.studentName} {student.fatherName}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Tabs.TabPane>
                );
              }
            )}
          </Tabs>

          <Row justify="center" align="middle" style={{ marginTop: 50 }}>
            <Form.Item
              style={{
                backgroundColor: "red",
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
  }
}
