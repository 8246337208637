import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";

export const InstructorList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" title={"Name"} />
      <TextField source="age" title={"Age"} />
      <TextField source="country" title={"Country"} />
      <TextField source="canTeach" title={"Can teach"} />
    </Datagrid>
  </List>
);
