import React from "react";
import { Title } from "react-admin";
import {
  Form,
  Input,
  Button,
  Row,
  Select,
  DatePicker,
  Space,
  Tabs,
  Checkbox,
} from "antd";
import axios from "axios";

import { Course, Group, School, StudentGroup, StudentLevel } from "../../types";
import os from "os";

type CreateStudentState = {
  availableCourses: Array<any>;
  availableSessions: Array<any>;
  isRegCourseModalOpen: boolean;
  allSchools: Array<School> | [];
  allGroups: Array<Group> | [];
  studentGroups: Array<StudentGroup> | [];
  studentLevels: Array<StudentLevel> | [];
};

const API = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;
export class CreateStudent extends React.Component<any, CreateStudentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      availableCourses: [],
      availableSessions: [],
      isRegCourseModalOpen: false,
      allSchools: [],
      allGroups: [],
      studentGroups: [],
      studentLevels: [],
    };
  }

  componentDidMount() {
    axios.get(API + "/schools").then((schools) => {
      this.setState({ allSchools: schools.data });
    });
    axios.get(API + "/groups").then((groups) => {
      this.setState({ allGroups: groups.data });
    });

    axios.get(API + "/courses").then((courses) => {
      this.setState({ availableCourses: courses.data });
    });
    axios.get(API + "/sessions").then((sessions) => {
      this.setState({ availableSessions: sessions.data });
    });
  }

  render() {
    return (
      <div>
        <Title title={"Add student"} />
        <Form
          name="basic"
          onFinish={(formData) => {
            // @ts-ignore
            let groups = this.state.studentGroups.map((studentGroup) => {
              return {
                group: this.state.allGroups.filter(
                  (group) => group.id === studentGroup.groupId
                )[0],
                studentLevel: studentGroup.studentLevel
                  ? studentGroup.studentLevel
                  : undefined,
                isCompleted: studentGroup.isCompleted
                  ? studentGroup.isCompleted
                  : false,
                payment: undefined,
                attendedSessions: [],
              };
            });
            // @ts-ignore
            let studentLevels = this.state.studentLevels.map((studentLevel) => {
              return {
                courseType: studentLevel.courseType,
                isWaiting: studentLevel.isWaiting
                  ? studentLevel.isWaiting
                  : false,
                level: studentLevel.level ? studentLevel.level : undefined,
              };
            });
            let student = {
              studentName: formData.studentName,
              fatherName: formData.fatherName,
              motherName: formData.motherName ? formData.motherName : undefined,
              age: formData.age,
              gender: formData.gender,
              country: formData.country,
              notes: formData.notes,
              motherLanguage: formData.motherLanguage,
              whatsappNumber1: formData.whatsappNumber1,
              whatsappNumber2: formData.whatsappNumber2
                ? formData.whatsappNumber2
                : undefined,
              joinedAt: formData.joinedAt ? formData.joinedAt : new Date(),
              parentFacebookProfile: formData.parentFacebookProfile
                ? formData.parentFacebookProfile
                : undefined,
              school: formData.school
                ? this.state.allSchools[formData.school]
                : undefined,
              groups: groups,
              levels: studentLevels,
            };
            console.log("student->", student);

            axios
              .post(API + "/students", student)
              .then(() => {
                this.props.history.push("/students");
              })
              .catch((err) => {
                console.log("inside error->", err);
              });
          }}
        >
          <div>
            <Form.Item
              style={{ width: "45%", display: "inline-block" }}
              label="Student name"
              name="studentName"
              rules={[
                { required: true, message: "Please enter student name!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ width: "45%", display: "inline-block", float: "right" }}
              label="Father name"
              name="fatherName"
              rules={[{ required: true, message: "Please enter father name!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ width: "45%", display: "inline-block" }}
              label="Mother name"
              name="motherName"
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ width: "45%", display: "inline-block", float: "right" }}
              label="Age"
              name="age"
              rules={[
                {
                  required: true,
                  message: "Please enter student age!",
                },
              ]}
            >
              <Input type={"number"} min={0} />
            </Form.Item>

            <Form.Item
              name={"gender"}
              label={"Gender"}
              rules={[
                {
                  required: true,
                  message: "Please select the student gender!",
                },
              ]}
            >
              <Select
                onSelect={(selectedStatus) => {
                  console.log("selectedStatus->", selectedStatus);
                  // @ts-ignore
                  //newCourse.gender = selectedStatus;
                }}
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value={"Female"}>Female</Select.Option>
                <Select.Option value={"Not specified"}>
                  Not specified
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{ width: "45%", display: "inline-block", float: "right" }}
              label="Country"
              name="country"
              rules={[
                { required: true, message: "Please enter country name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Primary whatsApp number"
            name="whatsappNumber1"
            rules={[
              {
                required: true,
                message: "Please enter primary whatsApp number!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Secondary whatsApp number"
            name="whatsappNumber2"
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block", float: "right" }}
            label="Student joined at"
            name="joinedAt"
          >
            <Space direction="vertical">
              <DatePicker />
            </Space>
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block", float: "right" }}
            label="parent facebook profile"
            name="parentFacebookProfile"
          >
            <Input />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter the mother language!",
              },
            ]}
            label={"Student mother language"}
            name={"motherLanguage"}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {/* @ts-ignore*/}
              <Select.Option key={"English"} value={"English"}>
                English
              </Select.Option>
              <Select.Option key={"Turkish"} value={"Turkish"}>
                Turkish
              </Select.Option>
              <Select.Option key={"Arabic"} value={"Arabic"}>
                Arabic
              </Select.Option>
              <Select.Option key={"German"} value={"German"}>
                German
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label={"Student school"} name={"school"}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {/* @ts-ignore*/}
              {this.state.allSchools.map((school: School, index: number) => {
                return (
                  <Select.Option key={school.id} value={index}>
                    {school.nameAr} - {school.nameEn}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Student notes"
            name={"notes"}
          >
            <Input.TextArea />
          </Form.Item>

          <h2>Student levels:</h2>
          <Button
            onClick={() => {
              let studentLevels = this.state.studentLevels;
              // @ts-ignore
              studentLevels.push({});
              // @ts-ignore
              this.setState({ studentLevels: studentLevels });
            }}
          >
            Add student level
          </Button>

          <Tabs
            defaultActiveKey="0"
            onChange={(e) => console.log("inside onChange->", e)}
          >
            {/*@ts-ignore*/}
            {this.state.studentLevels.map(
              (studentLevel: StudentLevel, index: number) => {
                return (
                  <Tabs.TabPane tab={"Student level " + index} key={index}>
                    <Form.Item
                      label={"Select course type"}
                      name={"courseType" + index}
                      rules={[
                        {
                          required: true,
                          message: "Please select course!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        onSelect={(courseType: any) => {
                          console.log("selected courseType->", courseType);
                          studentLevel.courseType = courseType;
                          let studentLevels = this.state.studentLevels;
                          studentLevels[index] = studentLevel;
                          this.setState({ studentLevels: studentLevels });
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        <Select.Option key={"English"} value={"English"}>
                          English
                        </Select.Option>
                        <Select.Option key={"German"} value={"German"}>
                          German
                        </Select.Option>
                        <Select.Option
                          key={"Programming"}
                          value={"Programming"}
                        >
                          Programming
                        </Select.Option>
                      </Select>
                    </Form.Item>

                    <div>
                      <Form.Item
                        style={{ width: "45%", display: "inline-block" }}
                        valuePropName={"checked"}
                      >
                        <Checkbox
                          onChange={(e) => {
                            studentLevel.isWaiting = e.target.checked;
                            let studentLevels = this.state.studentLevels;
                            studentLevels[index] = studentLevel;
                            this.setState({ studentLevels: studentLevels });
                          }}
                        >
                          Add student to waiting
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <Form.Item
                      style={{ width: "45%", display: "inline-block" }}
                      label="Student level"
                      name={"studentTypeLevel" + index}
                    >
                      <Input
                        type={"number"}
                        min={0}
                        max={10}
                        onBlur={(e) => {
                          studentLevel.level = Number(e.target.value);
                          let studentLevels = this.state.studentLevels;
                          studentLevels[index] = studentLevel;
                          this.setState({ studentLevels: studentLevels });
                        }}
                      />
                    </Form.Item>
                  </Tabs.TabPane>
                );
              }
            )}
          </Tabs>

          <h2>Group sessions:</h2>
          <Button
            onClick={() => {
              let groups = this.state.studentGroups;
              // @ts-ignore
              groups.push({});
              // @ts-ignore
              this.setState({ studentGroups: groups });
            }}
          >
            Add group session
          </Button>

          <Tabs
            defaultActiveKey="0"
            onChange={(e) => console.log("inside onChange->", e)}
          >
            {/*@ts-ignore*/}
            {this.state.studentGroups.map(
              (studentGroup: StudentGroup, index: number) => {
                return (
                  <Tabs.TabPane tab={"Student Group " + index} key={index}>
                    <div>
                      <label>Select group: </label>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        onSelect={(groupIndex: any) => {
                          studentGroup.groupId = this.state.allGroups[
                            groupIndex
                          ].id;
                          let studentGroupsList = this.state.studentGroups;
                          studentGroupsList[index] = studentGroup;
                          // @ts-ignore
                          this.setState({ studentGroups: studentGroupsList });
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {/*@ts-ignore*/}
                        {this.state.allGroups.map(
                          (group: Group, index: number) => {
                            console.log();
                            return (
                              <Select.Option key={group.id} value={index}>
                                {group.name}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </div>

                    <div>
                      <Form.Item
                        style={{ width: "45%", display: "inline-block" }}
                        label="Student level"
                        name={"studentLevel" + index}
                        rules={[
                          {
                            required: true,
                            message: "Please enter student level!",
                          },
                        ]}
                      >
                        <Input
                          type={"number"}
                          min={0}
                          max={10}
                          onBlur={(e) => {
                            studentGroup.studentLevel = Number(e.target.value);
                            let studentGroupsList = this.state.studentGroups;
                            studentGroupsList[index] = studentGroup;
                            // @ts-ignore
                            this.setState({ studentGroups: studentGroupsList });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        style={{ width: "45%", display: "inline-block" }}
                        name={"studentLevel" + index}
                        valuePropName={"checked"}
                      >
                        <Checkbox
                          onChange={(e) => {
                            studentGroup.isCompleted = e.target.checked;
                            let studentGroupsList = this.state.studentGroups;
                            studentGroupsList[index] = studentGroup;
                            // @ts-ignore
                            this.setState({ studentGroups: studentGroupsList });
                          }}
                        >
                          Is group/course finished
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Tabs.TabPane>
                );
              }
            )}
          </Tabs>

          {/*<div style={{ width: "45%", display: "inline-block" }}>
            <Form.Item
              label="Courses"
              shouldUpdate={(prevValues, curValues) =>
                prevValues.users !== curValues.users
              }
            >
              {this.state.studentCourses && (
                <ul>
                  {this.state.studentCourses.map((course, index) => {
                    return (
                      <li key={index} className="user">
                        {course.course.name} - Level {course.course.level}
                      </li>
                    );
                  })}
                </ul>
              )}
            </Form.Item>
            <Button
              htmlType="button"
              style={{ margin: "0 8px" }}
              onClick={() =>
                this.setState({
                  isRegCourseModalOpen: true,
                })
              }
            >
              Add student to a course
            </Button>
          </div>*/}

          {/*<AddCourseModal
              onCancel={this.handleCancel}
              onOk={this.handleOnOk}
              sessions={this.state.availableSessions}
              courses={this.state.availableCourses}
              isVisible={this.state.isRegCourseModalOpen}
            />*/}
          <Row justify="center" align="middle" style={{ marginTop: 50 }}>
            <Form.Item
              style={{
                backgroundColor: "red",
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
  }
}
