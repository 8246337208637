import {
  DateField,
  EditButton,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from "react-admin";
import React from "react";
import { Chip, Link } from "@material-ui/core";

import { Tabs } from "antd";

export const CourseShow = (props: any) => {
  let { record } = useShowController(props);
  console.log("record-->", record);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" label={"Name"} />
        <TextField source="level" label={"Level"} />
        <TextField source="groupNumber" label={"Group number"} />
        <label>Students: </label>
        {record?.students?.map((student: any) => {
          console.log("student.name->", student);
          return (
            <Link href={"/students/" + student.id + "/show"}>
              <Chip
                style={{
                  margin: "10px",
                  cursor: "pointer",
                }}
                label={student.studentName}
              />
            </Link>
          );
        })}
        <label>Sessions:</label>
        <Tabs
          defaultActiveKey="0"
          onChange={(e) => console.log("inside onChange->", e)}
        >
          {record?.sessions?.map((session: any, index: number) => {
            console.log("session.name->", session);
            if (session.session)
              return (
                <Tabs.TabPane
                  tab={"Session " + session.session.name}
                  key={index}
                >
                  <div>
                    <label>Session name: </label>
                    <Link href={"/sessions/" + session.session.id + "/show"}>
                      <Chip
                        style={{
                          backgroundColor: session.taught ? "green" : "gray",
                          margin: "10px",
                          cursor: "pointer",
                        }}
                        label={session.session.name}
                      />
                    </Link>
                  </div>
                  <div>
                    <h2>Students: </h2>
                    {session.attendedStudents.map((student: any) => {
                      return (
                        <Link href={"/students/" + student.id + "/show"}>
                          <p>
                            {student.studentName} {student.fatherName}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                  <div>
                    <label>Session date: </label>
                    <p>
                      {new Date(session.date._seconds * 1000).toDateString()}
                    </p>
                  </div>
                  <div>
                    <label>Instructor: </label>
                    <Link
                      href={"/instructors/" + session.instructor.id + "/show"}
                    >
                      <p>{session.instructor.name}</p>
                    </Link>
                  </div>
                  <div>
                    <label>Zoom url: </label>
                    {console.log("session.zoomUrl->", session.zoomUrl)}
                    <Link href={session.zoomUrl ? session.zoomUrl : ""}>
                      <p>
                        {session.zoomUrl ? "session.zoomUrl" : "Not added yet"}
                      </p>
                    </Link>
                  </div>
                </Tabs.TabPane>
              );
          })}
        </Tabs>
        <DateField source="createdAt" label={"Course created at"} />
        <DateField source="startedAt" label={"Course started at"} />
        <TextField source="whatsappGroupId" label={"Whats app group id"} />
        <TextField source="numberOfSessions" label={"Number of sessions"} />
      </SimpleShowLayout>
    </Show>
  );
};
