import React, { useState } from "react";
import { DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment";

type AddSessionToCourseModal = {
  isSessionsModalVisible: boolean;
  onOk: (session: any) => void;
  onCancel: () => void;
  allSessions: Array<any>;
  allStudents: Array<any>;
  allInstructors: Array<any>;
};
export const AddSessionToCourseModal = ({
  isSessionsModalVisible,
  allSessions,
  allStudents,
  allInstructors,
  onCancel,
  onOk,
}: AddSessionToCourseModal) => {
  const [session, setSession] = useState<{
    taught: boolean;
    session: any;
    attendedStudents: Array<any>;
    date: any;
    instructor: any;
    zoomUrl?: string;
  }>({
    taught: false,
    session: {},
    attendedStudents: [],
    date: new Date(),
    instructor: {},
  });

  const [form] = Form.useForm();

  return (
    <Modal
      title="Add session"
      visible={isSessionsModalVisible}
      onOk={(e) => {
        form.submit();
        /*form
          .validateFields()
          .then((e) => {
            console.log("va ->", e);
          })
          .catch((err) => console.log("validerr->", err));*/
      }}
      onCancel={(c) => {
        /*
        form.resetFields();
        onCancel();*/
      }}
    >
      <Form
        form={form}
        onFinish={(formData: any) => {
          let session = {
            taught: formData.taught === "Yes",
            session: allSessions.filter(
              (session) => session.id === formData.session
            )[0],
            attendedStudents: allStudents.filter(
              (student) => student.id !== formData.attendedStudents
            ),
            date: formData.date ? new Date(formData.date) : new Date(),
            instructor: allInstructors.filter(
              (instructor) => instructor.id !== formData.instructor
            )[0],
            zoomUrl: formData.zoomUrl,
          };
          onOk(session);
        }}
      >
        <Form.Item
          rules={[
            { required: true, message: "Please Select if session taught" },
          ]}
          name={"taught"}
          label={"Is session taught?"}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            onSelect={(newValue) => {
              console.log("from select newValue-->", newValue);
            }}
            onDeselect={(newValue) => {
              console.log("from select newValue-->", newValue);
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Select.Option value={"Yes"}>Yes</Select.Option>
            <Select.Option value={"No"}>No</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Please select Session!" }]}
          name={"session"}
          label={"Select session"}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {allSessions.map((session: any, index: number) => {
              return (
                <Select.Option value={session.id}>{session.name}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item name={"attendedStudents"} label={"Select attenden students"}>
          <Select
            showSearch
            mode={"multiple"}
            style={{ width: 200 }}
            /*onDeselect={(deselectedSessionId) => {
                let ses = selectedSessions;
                console.log("ses->", ses);
                console.log("deselectedSessionId->", deselectedSessionId);
                ses = ses.filter(
                  (session) => session.session.id !== deselectedSessionId
                );
                setSelectedSessions(ses);
              }}*/
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {allStudents.map((student: any, index: number) => {
              console.log("allStudents - student->", student);
              return (
                <Select.Option value={student.id}>
                  {student.studentName} {student.fatherName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          initialValue={moment(new Date())}
          rules={[{ required: true, message: "Please enter Session date!" }]}
          name={"date"}
          label={"Session date"}
        >
          <DatePicker
          /* onChange={(e: any) => {
                let course = this.state.course;
                course.sessions[index].date = new Date(e);
                this.setState({ course: course });
              }}*/
          />
        </Form.Item>

        <Form.Item name={"instructor"} label={"Select instructor"}>
          <Select
            showSearch
            style={{ width: 200 }}
            onSelect={(newValue) => {
              console.log("from select newValue-->", newValue);
            }}
            onDeselect={(newValue) => {
              console.log("from select newValue-->", newValue);
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {allInstructors.map((instructor) => {
              return (
                <Select.Option value={instructor.id}>
                  {instructor.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name={"zoomUrl"} label={"Zoom URL"}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
