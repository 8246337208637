import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Select, Radio, Checkbox } from "antd";
import { useRedirect } from "react-admin";
import axios from "axios";
import {
  ContentTypes,
  Course,
  CourseTypes,
  MediaContent,
  MultipleChoiceContent,
  TextContent,
} from "../../types";
import os from "os";

export const CreateContent = (props: any) => {
  const redirect = useRedirect();

  const [selectedContentType, setSelectedContentType] = useState<ContentTypes>(
    "Video"
  );
  const [contentData, setContentData] = useState({});
  const [choices, setChoices] = useState();
  const [contentCourseType, setContentCourseType] = useState<CourseTypes>();
  const [allCourses, setAllCourses] = useState<Array<Course> | []>([]);
  const API = os?.hostname()?.includes("local")
    ? process.env.REACT_APP_DEV_ENDPOINT
    : process.env.REACT_APP_PROD_ENDPOINT;
  useEffect(() => {
    axios
      .get(process.env.API + "/courses")
      .then((courses) => {
        setAllCourses(courses.data);
      })
      .catch((err) => {
        console.log("inside error", err);
      });
  }, []);

  const resetContentData = () => setContentData({});
  const renderMediaContent = () => {
    return (
      <>
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Youtube url"
          name="youtubeUrl"
        >
          <Input
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.youtubeUrl = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Google storage url"
          name="googleStorageUrl"
        >
          <Input
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.googleStorageUrl = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content description"
          name="description"
          rules={[
            { required: true, message: "Please enter content description!" },
          ]}
        >
          <Input.TextArea
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.description = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>
      </>
    );
  };

  const renderTextContent = () => {
    return (
      <>
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Text"
          name="text"
          rules={[{ required: true, message: "Please enter content text!" }]}
        >
          <Input
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.text = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content action"
          name="action"
          rules={[{ required: true, message: "Please enter content action!" }]}
        >
          <Input
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.action = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content description"
          name="description"
        >
          <Input
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.description = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>
      </>
    );
  };

  const renderMultipleChoiceContent = () => {
    return (
      <>
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content description"
          name="description"
          rules={[
            { required: true, message: "Please enter content description!" },
          ]}
        >
          <Input
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.description = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content sentence1"
          name="sentence1"
          rules={[
            { required: true, message: "Please enter content sentence1!" },
          ]}
        >
          <Input
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.sentence1 = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content sentence2"
          name="sentence2"
          rules={[
            { required: true, message: "Please enter content sentence2!" },
          ]}
        >
          <Input
            onBlur={(e) => {
              let data = contentData;
              // @ts-ignore
              data.sentence2 = e.target.value;
              setContentData(data);
            }}
          />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content choices"
          name="choices"
          rules={[
            {
              required: true,
              message: "Please enter content choices!",
            },
          ]}
        >
          <Select
            onChange={(choicesList: any) => {
              setChoices(choicesList);
              let choices = choicesList.map((c: any) => {
                return {
                  choice: c,
                  isTrue: false,
                };
              });
              let data = contentData;
              // @ts-ignore
              data.choices = choices;
              setContentData(data);
            }}
            mode="tags"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Please select the correct answer"
          name="correctAnswer"
          rules={[
            {
              required: true,
              message: "Please enter content choices!",
            },
          ]}
        >
          <Radio.Group
            options={choices}
            onChange={(e) => {
              let data = contentData;
              // @ts-ignore
              data.choices.forEach((c) => {
                c.choice === e.target.value
                  ? (c.isTrue = true)
                  : (c.isTrue = false);
              });
              setContentData(data);
            }}
          />
        </Form.Item>
      </>
    );
  };
  const renderContentDataForm = () => {
    switch (selectedContentType) {
      case "Video":
        return renderMediaContent();
      case "Picture":
        return renderMediaContent();
      case "Text":
        return renderTextContent();
      case "Multiple choice":
        return renderMultipleChoiceContent();
    }
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={(formData) => {
          formData.data = contentData;
          let contentCourses = formData.contentCourses
            ? formData.contentCourses.map(
                (courseIndex: number) => allCourses[courseIndex]
              )
            : [];
          let content = {
            name: formData.name,
            contentCourseType: formData.contentCourseType,
            contentCourses: contentCourses,
            keywords: formData.keywords,
            contentType: formData.contentType,
            data: contentData,
          };
          axios
            .post(API + "/contents", content)
            .then(() => {
              redirect("/contents");
            })
            .catch((err) => {
              console.log("inside error->", err);
            });
        }}
      >
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content name"
          name="name"
          rules={[{ required: true, message: "Please enter content name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"contentCourseType"}
          label={"Content course type"}
          rules={[
            {
              required: true,
              message: "Please select the content coures type!",
            },
          ]}
        >
          <Select
            onSelect={(type: CourseTypes) => {
              setContentCourseType(type);
            }}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Select.Option value={"English"}>English</Select.Option>
            <Select.Option value={"German"}>German</Select.Option>
            <Select.Option value={"Programming"}>Programming</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name={"contentCourses"} label={"Content suits for"}>
          <Select
            mode={"multiple"}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {/*@ts-ignore*/}
            {allCourses.map((course: Course, index: number) => {
              let courseWithLevel = course.name + "." + course.level;
              if (course.name === contentCourseType) {
                return (
                  <Select.Option key={course.id} value={index}>
                    {courseWithLevel}
                  </Select.Option>
                );
              }
            })}
          </Select>
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Content keywords"
          name="keywords"
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Keywords"
          />
        </Form.Item>

        <Form.Item
          name={"contentType"}
          label={"Content type"}
          rules={[
            {
              required: true,
              message: "Please select the content type!",
            },
          ]}
          initialValue={selectedContentType}
        >
          <Radio.Group
            onChange={(e) => {
              setSelectedContentType(e.target.value);
              resetContentData();
            }}
            value={selectedContentType}
          >
            <Radio value={"Video"}>Video</Radio>
            <Radio value={"Text"}>Text</Radio>
            <Radio value={"Multiple choice"}>Multiple choice</Radio>
            <Radio value={"Picture"}>Picture</Radio>
          </Radio.Group>
        </Form.Item>
        {renderContentDataForm()}
        <Row justify="center" align="middle" style={{ marginTop: 50 }}>
          <Form.Item
            style={{
              backgroundColor: "red",
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
