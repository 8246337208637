import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";

export const SessionsList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="sessionPdfUrl" />
      <TextField source="duration" />
      <TextField source="summaryURL" />
    </Datagrid>
  </List>
);
