import React from "react";
import axios from "axios";
import { EditButton, Title } from "react-admin";
import { Tabs, Typography } from "antd";
import { Chip, Link } from "@material-ui/core";
import os from "os";
type ShowStudentState = {
  student: any;
};

const API = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;
export class ShowStudent extends React.Component<any, ShowStudentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      student: {},
    };
  }

  componentDidMount() {
    axios
      .get(API + "/students/" + this.props.id)
      .then((student) => {
        console.log("inside return ->", student.data);
        this.setState({ student: student.data });
      })
      .catch((err) => {
        console.log("inside error", err);
      });
    /*  axios.get("http://localhost:8080/sessions").then((sessions) => {
        this.setState({ availableSessions: sessions.data });
      });*/
  }

  render() {
    return (
      <div>
        {this.state.student.id && (
          <>
            <EditButton basePath={"/students"} record={this.state.student} />
            <Title title={"Student: " + this.state.student.id} />
            <div>
              <text style={{ color: "gray" }}>Student name:</text>
              <br />
              <text>{this.state.student.studentName}</text>
            </div>
            <div>
              <text style={{ color: "gray" }}>Student father name:</text>
              <br />
              <text>{this.state.student.fatherName}</text>
            </div>
            <div>
              <text style={{ color: "gray" }}>Student mother name:</text>
              <br />
              <text>
                {this.state.student.motherName
                  ? this.state.student.motherName
                  : "None"}
              </text>
            </div>

            <div>
              <text style={{ color: "gray" }}>Student age:</text>
              <br />
              <text>{this.state.student.age}</text>
            </div>

            <div>
              <text style={{ color: "gray" }}>Student gender:</text>
              <br />
              <text>{this.state.student.gender}</text>
            </div>
            <div>
              <text style={{ color: "gray" }}>Student country:</text>
              <br />
              <text>{this.state.student.country}</text>
            </div>
            <div>
              <text style={{ color: "gray" }}>Student primary:</text>
              <br />
              <text>{this.state.student.whatsappNumber1}</text>
            </div>
            <div>
              <text style={{ color: "gray" }}>Student secondary:</text>
              <br />
              <text>
                {this.state.student.whatsappNumber2
                  ? this.state.student.whatsappNumber2
                  : "None"}
              </text>
            </div>
            <div>
              <text style={{ color: "gray" }}>Student joined at:</text>
              <br />
              <text>
                {new Date(this.state.student.createdAt).toDateString()}
              </text>
            </div>
            <div>
              <text style={{ color: "gray" }}>Parent facebook profile:</text>
              <br />
              <text>
                {this.state.student.parentFacebookProfile
                  ? this.state.student.parentFacebookProfile
                  : "None"}
              </text>
            </div>

            <Tabs
              defaultActiveKey="0"
              onChange={(e) => console.log("inside onChange->", e)}
            >
              {this.state.student.courses.map((course: any, index: number) => {
                return (
                  <Tabs.TabPane
                    tab={"Course " + course.course.name}
                    key={index}
                  >
                    <div>
                      <text style={{ color: "gray" }}>Course completed:</text>
                      <br />
                      <text>{course.completed ? "Yes" : "No"}</text>
                    </div>
                    <div>
                      <text style={{ color: "gray" }}>Course Price:</text>
                      <br />
                      <text>{course.amount}</text>
                    </div>
                    <br />
                    <div>
                      <text style={{ color: "gray" }}>Currency:</text>
                      <br />
                      <text>{course.currency}</text>
                    </div>

                    <div>
                      <text style={{ color: "gray" }}>Payment receiver:</text>
                      <br />
                      <text>{course.paymentReceiver}</text>
                    </div>

                    <div>
                      <text style={{ color: "gray" }}>Status:</text>
                      <br />
                      <text>{course.status}</text>
                    </div>
                    <div>
                      <text style={{ color: "gray" }}>Student level:</text>
                      <br />
                      {console.log("show Course-->", course)}
                      <text>{course.studentLevel}</text>
                    </div>

                    <div>
                      <text style={{ color: "gray" }}>Joined course at:</text>
                      <br />
                      <text>{new Date(course.joinedAt).toDateString()}</text>
                    </div>

                    <Typography.Link
                      href={"/courses/" + course.course.id + "/show"}
                    >
                      Course details
                    </Typography.Link>
                    <br />
                    <div>
                      <text style={{ color: "gray" }}>Group number:</text>
                      <br />
                      <text>{course.course.groupNumber}</text>
                    </div>
                    <div>
                      <text style={{ color: "gray" }}>Course level:</text>
                      <br />
                      <text>{course.course.level}</text>
                    </div>
                    <br />
                    <text style={{ color: "gray" }}>Sessions:</text>
                    <br />
                    {course.course.sessions.map((session: any) => {
                      return (
                        <div
                          style={{
                            display: "inline-block",
                            border: "1px solid lightGray",
                            margin: "10px",
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          <Link
                            href={"/sessions/" + session.session.id + "/show"}
                          >
                            <Chip
                              style={{
                                backgroundColor:
                                  new Date() <
                                  new Date(session.date._seconds * 1000)
                                    ? "gray"
                                    : session.attendedStudentsIds.includes(
                                        this.state.student.id
                                      )
                                    ? "green"
                                    : "red",
                                marginRight: "15px",
                                cursor: "pointer",
                              }}
                              label={session.session.name}
                            />
                          </Link>
                          <div>
                            <text style={{ color: "gray" }}>Session Date:</text>
                            <br />
                            <text>
                              {new Date(
                                session.date._seconds * 1000
                              ).toDateString()}
                            </text>
                          </div>
                        </div>
                      );
                    })}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </>
        )}
      </div>
    );
  }
}
