import { Datagrid, DateField, EditButton, List, TextField } from "react-admin";
import React from "react";

export const StudentsList = (props: any, record: any) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="studentName" label={"Student name"} />
        <TextField source="fatherName" label={"Father name"} />
        <TextField source="country" label={"Country"} />
        <TextField source="whatsappNumber1" label={"Whatsapp number 1"} />
        <DateField source="createdAt" label={"Joined at"} />
      </Datagrid>
    </List>
  );
};
