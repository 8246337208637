import React from "react";
import { Create, SimpleForm, TextInput, useRedirect } from "react-admin";

export const CreateSession = (props: any) => {
  const redirect = useRedirect();

  return (
    <Create {...props} onSuccess={() => redirect("/sessions")}>
      <SimpleForm>
        <TextInput source="name" required={true} title={"Session name"} />
        <TextInput
          source="sessionPdfUrl"
          required={true}
          title={"Session PDF url"}
        />
        <TextInput
          type={"number"}
          source="duration"
          required={true}
          title={"Session duration"}
        />
        <TextInput source="summaryURL" />
      </SimpleForm>
    </Create>
  );
};
