import React from "react";
import { Admin, Resource } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { FirebaseAuthProvider } from "react-admin-firebase";
import { createBrowserHistory as createHistory } from "history";
import {
  CourseEdit,
  CourseList,
  CreateSession,
  CreateStudent,
  EditStudent,
  SessionEdit,
  SessionsList,
  StudentsList,
  CourseShow,
  SessionsShow,
  ShowStudent,
  CreateCourse,
  InstructorList,
  CreateInstructor,
  EditInstructor,
  ShowInstructor,
  ContentsList,
  GroupsList,
  PaymentsList,
  SchoolsList,
  CommissionsList,
  CreateSchool,
  CreateContent,
  CreateGroup,
  CreatePayment,
  CreateCommission,
} from "./pages";

import { firebaseConfig } from "./config";
import { RAFirebaseOptions } from "react-admin-firebase/dist/providers/options";
import os from "os";

const history = createHistory();
const options: RAFirebaseOptions = {
  logging: false,
};
const authProvider = FirebaseAuthProvider(firebaseConfig, options);
const dataProvider = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;
function App() {
  return (
    <Admin
      history={history}
      authProvider={authProvider}
      dataProvider={simpleRestProvider(dataProvider!)}
    >
      <Resource
        name="students"
        list={StudentsList}
        show={ShowStudent}
        edit={EditStudent}
        create={CreateStudent}
      />
      <Resource
        name="sessions"
        list={SessionsList}
        show={SessionsShow}
        edit={SessionEdit}
        create={CreateSession}
      />
      <Resource
        name="courses"
        list={CourseList}
        show={CourseShow}
        edit={CourseEdit}
        create={CreateCourse}
      />

      <Resource
        name="instructors"
        list={InstructorList}
        create={CreateInstructor}
        show={ShowInstructor}
        edit={EditInstructor}
      />

      <Resource
        name="contents"
        list={ContentsList}
        create={CreateContent}
        show={ShowInstructor}
        edit={EditInstructor}
      />

      <Resource
        name="groups"
        list={GroupsList}
        create={CreateGroup}
        show={ShowInstructor}
        edit={EditInstructor}
      />

      <Resource
        name="payments"
        list={PaymentsList}
        create={CreatePayment}
        show={ShowInstructor}
        edit={EditInstructor}
      />

      <Resource
        name="schools"
        list={SchoolsList}
        create={CreateSchool}
        show={ShowInstructor}
        edit={EditInstructor}
      />
      <Resource
        name="commissions"
        list={CommissionsList}
        create={CreateCommission}
        show={ShowInstructor}
        edit={EditInstructor}
      />
    </Admin>
  );
}

export default App;
