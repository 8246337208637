import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Select } from "antd";
import { useRedirect } from "react-admin";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CourseTypes } from "../../types";
import { CourseDailyProgramTemp } from "../../constants";
import os from "os";
export const CreateCourse = (props: any) => {
  const redirect = useRedirect();
  const [allSession, setAllSession] = useState([]);
  const [program, setProgram] = useState<any>();
  const [selectedCourseType, setSelectedCourseType] = useState<CourseTypes>();
  const API = os?.hostname()?.includes("local")
    ? process.env.REACT_APP_DEV_ENDPOINT
    : process.env.REACT_APP_PROD_ENDPOINT;

  useEffect(() => {
    let tempProgram = CourseDailyProgramTemp;
    // @ts-ignore
    setProgram(tempProgram);

    axios.get(API + "/sessions").then((sessions) => {
      setAllSession(sessions.data);
    });

    return () => {
      // @ts-ignore
      Object.entries(tempProgram).forEach(([day, contents]) => {
        // @ts-ignore
        tempProgram[day] = [];
      });
    };
  }, []);

  /*const grid = 8;

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250,
  });
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });*/
  return (
    <div>
      <Form
        name="basic"
        onFinish={(formData) => {
          let sessions = formData.sessions
            ? formData.sessions.map(
                (sessionsIndex: number) => allSession[sessionsIndex]
              )
            : [];

          let newCourse = {
            name: formData.name,
            level: formData.level,
            sessions: sessions,
            contents: [],
            program: program,
          };
          axios
            .post(API + "/courses", newCourse)
            .then(() => {
              redirect("/courses");
            })
            .catch((err) => {
              console.log("inside error->", err);
            });
        }}
      >
        <Form.Item
          name={"name"}
          label={"Course name"}
          rules={[{ required: true, message: "Please enter course name !" }]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            onChange={(selectedType: CourseTypes) => {
              setSelectedCourseType(selectedType);
            }}
          >
            <Select.Option key={"English"} value={"English"}>
              English
            </Select.Option>
            <Select.Option key={"German"} value={"German"}>
              German
            </Select.Option>
            <Select.Option key={"Programming"} value={"Programming"}>
              Programming
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Course level"
          name="level"
          rules={[{ required: true, message: "Please enter course level!" }]}
        >
          <Input type={"number"} min={0} max={10} />
        </Form.Item>
        {/** USE IT IN EDIT COURSE
        <Form.Item name={"contents"} label={"Contents"}>
          <Select
            mode={"multiple"}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {allContents.map((content: Content, index) => {
              if (content.contentCourseType === selectedCourseType) {
                return (
                  <Select.Option key={content.name + index} value={index}>
                    {content.name}
                  </Select.Option>
                );
              }
            })}
          </Select>
        </Form.Item>*/}
        <Form.Item name={"sessions"} label={"Sessions"}>
          <Select
            mode={"multiple"}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {allSession.map((session: any, index) => {
              return (
                <Select.Option key={session.name + index} value={index}>
                  {session.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {/** USE IT IN EDIT COURSE
        <div>
          <label>Create daily program:</label>
          {program &&
            Object.entries(program).map(([day, contents]) => {
              return (
                <div key={"Day" + day}>
                  <div>
                    <p style={{ display: "inline-block", marginRight: 20 }}>
                      Day: {day}
                    </p>
                    <Form.Item name={"day " + day}>
                      <Select
                        mode={"multiple"}
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(selectedContentsIndexes) => {
                          let programClone = program;
                          // @ts-ignore
                          let selectedContents = selectedContentsIndexes.map(
                            (contentIndex: number) => allContents[contentIndex]
                          );
                          // @ts-ignore
                          programClone[day] = selectedContents;
                          setProgram(programClone);
                        }}
                      >
                        {allContents.map((content: Content, index) => {
                          if (
                            content.contentCourseType === selectedCourseType
                          ) {
                            return (
                              <Select.Option
                                key={content.name + index}
                                value={index}
                              >
                                {content.name}
                              </Select.Option>
                            );
                          }
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              );
            })}
        </div>*/}
        {/*<div>
          <p>h</p>
          <DragDropContext onDragEnd={(e) => console.log("dtag->", e)}>
            <Droppable direction={"horizontal"} droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {allSession.map((item: any, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <p>w</p>
        </div>
*/}
        <Row justify="center" align="middle" style={{ marginTop: 50 }}>
          <Form.Item
            style={{
              backgroundColor: "red",
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
