import React from "react";
import { List, Datagrid, TextField } from "react-admin";

export const SchoolsList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="nameAr" label={"Arabic name"} />
      <TextField source="nameEn" label={"English name"} />
      <TextField source="schoolType" label={"School type"} />
    </Datagrid>
  </List>
);
