import React from "react";
import { Datagrid, DateField, EditButton, List, TextField } from "react-admin";

export const CourseList = (props: any, record: any) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="level" />
      </Datagrid>
    </List>
  );
};
