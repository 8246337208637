import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ArrayField,
  SingleFieldList,
} from "react-admin";

export const PaymentsList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="receiver" />
        <TextField label={"student name"} source="student.studentName" />
        <TextField label={"Father name"} source="student.fatherName" />
        <TextField label={"Mother name"} source="student.motherName" />
        <TextField label={"Status"} source="status" />
      </Datagrid>
    </List>
  );
};
