import React, { useState } from "react";
import { Form, Input, Button, Row, Select } from "antd";
import { useRedirect } from "react-admin";
import axios from "axios";
import os from "os";
const API = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;
export const CreateSchool = (props: any) => {
  const [allStudents, setAllStudents] = useState([]);
  const redirect = useRedirect();
  useState(() => {
    axios.get(API + "/students").then((students) => {
      setAllStudents(students.data);
    });
  });
  return (
    <div>
      <Form
        name="basic"
        onFinish={(formData) => {
          let students = formData.students.map(
            (studentIndex: number) => allStudents[studentIndex]
          );
          formData.students = students;
          axios
            .post(API + "/schools", formData)
            .then(() => {
              redirect("/schools");
            })
            .catch((err) => {
              console.log("inside error->", err);
            });
        }}
      >
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Arabic school name"
          name="nameAr"
          rules={[
            { required: true, message: "Please enter school name in arabic!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="English school name"
          name="nameEn"
          rules={[
            { required: true, message: "Please enter school name in english!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"schoolType"}
          label={"School type"}
          rules={[
            {
              required: true,
              message: "Please select the student gender!",
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Select.Option value="Arabic">Arabic</Select.Option>
            <Select.Option value={"Private"}>Private</Select.Option>
            <Select.Option value={"International"}>International</Select.Option>
            <Select.Option value={"Home School"}>Home School</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name={"students"} label={"Students"}>
          <Select
            mode={"multiple"}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {allStudents.map((student: any, index) => {
              return (
                <Select.Option value={index}>
                  {student.studentName + " " + student.fatherName}
                </Select.Option>
              );
            })}
            {/*<Select.Option value="Arabic">Arabic</Select.Option>
            <Select.Option value={"Private"}>Private</Select.Option>
            <Select.Option value={"International"}>International</Select.Option>
            <Select.Option value={"Home School"}>Home School</Select.Option>*/}
          </Select>
        </Form.Item>

        <Row justify="center" align="middle" style={{ marginTop: 50 }}>
          <Form.Item
            style={{
              backgroundColor: "red",
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
