import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Select,
  Tabs,
  DatePicker,
  Checkbox,
  Space,
} from "antd";
import { useRedirect } from "react-admin";
import axios from "axios";
import {
  CurrencyTypes,
  Group,
  PaymentMethodTypes,
  Student,
  StudentCommissionDTO,
} from "../../types";
import moment from "moment";
import { PaymentTypes } from "../../types/Commission";
import os from "os";

export const CreateCommission = (props: any) => {
  const redirect = useRedirect();

  const [allStudents, setAllStudents] = useState([]);
  const [commissions, setCommissions] = useState<
    Array<
      | StudentCommissionDTO
      | {
          student: any;
          isPaid: any;
          joinedAt: any;
          paymentType: any;
          currency: any;
          amount: any;
        }
    >
  >([]);
  const API = os?.hostname()?.includes("local")
    ? process.env.REACT_APP_DEV_ENDPOINT
    : process.env.REACT_APP_PROD_ENDPOINT;

  useEffect(() => {
    axios.get(API + "/students").then((students) => {
      setAllStudents(students.data);
    });
  }, []);

  return (
    <div>
      <Form
        name="basic"
        onFinish={(formData) => {
          let commission = {
            name: formData.name,
            paymentMethod: formData.paymentMethod,
            toPay: formData.toPay,
            generatedMoney: formData.generatedMoney,
            paid: formData.paid,
            commissions: commissions,
          };
          axios
            .post(API + "/commissions", commission)
            .then(() => {
              redirect("/commissions");
            })
            .catch((err) => {
              console.log("inside error->", err);
            });
        }}
      >
        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Full name"
          name="name"
          rules={[{ required: true, message: "Please enter the full name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"paymentMethod"}
          label={"Payment method"}
          rules={[
            {
              required: true,
              message: "Please select the payment method!",
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Select.Option value={"Paypal"}>Paypal</Select.Option>
            <Select.Option value={"Bank transfer"}>Bank transfer</Select.Option>
            <Select.Option value={"Vodafone cash"}>Vodafone cash</Select.Option>
            <Select.Option value={"Payoneer"}>Payoneer</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="To pay"
          name="toPay"
          rules={[{ required: true, message: "Please enter to pay amount!" }]}
        >
          <Input type={"number"} min={0} />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Total generated money"
          name="generatedMoney"
          rules={[
            { required: true, message: "Please enter total generated money!" },
          ]}
        >
          <Input type={"number"} min={0} />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Total paid money"
          name="paid"
          rules={[
            { required: true, message: "Please enter total paid money!" },
          ]}
        >
          <Input type={"number"} min={0} />
        </Form.Item>

        <h2>Commissions:</h2>
        <Button
          onClick={() => {
            let newCommissions = commissions;
            newCommissions.push({
              student: undefined,
              isPaid: false,
              joinedAt: new Date(),
              paymentType: undefined,
              currency: undefined,
              amount: undefined,
            });
            setCommissions([...newCommissions]);
          }}
        >
          Add commission
        </Button>
        <Tabs defaultActiveKey="0">
          {console.log()}
          {commissions?.map((commission: any, index: number) => {
            console.log();
            return (
              <Tabs.TabPane tab={"Session " + index} key={index}>
                <label>Select student: </label>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(selectedStudentId: string) => {
                    let selectedStd = allStudents.filter(
                      (student: Student) => student.id === selectedStudentId
                    )[0];
                    let allCommissions = commissions;
                    allCommissions[index].student = selectedStd;
                    // @ts-ignore
                    setCommissions(allCommissions);
                  }}
                >
                  {allStudents.map((student: Student, index: number) => {
                    return (
                      <Select.Option key={student.id} value={student.id}>
                        {student.studentName}-{student.fatherName}-
                        {student.motherName && student.motherName}
                      </Select.Option>
                    );
                  })}
                </Select>

                <div>
                  <Checkbox
                    onChange={(e) => {
                      let allCommissions = commissions;
                      allCommissions[index].isPaid = e.target.checked;
                      // @ts-ignore
                      setCommissions(allCommissions);
                    }}
                  >
                    Is paid
                  </Checkbox>
                </div>

                <div>
                  <label>Payment type: </label>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(paymentType: string) => {
                      let allCommissions = commissions;
                      allCommissions[index].paymentType = paymentType;
                      // @ts-ignore
                      setCommissions(allCommissions);
                    }}
                  >
                    <Select.Option value={"Monthly"}>Monthly</Select.Option>
                    <Select.Option value={"Once"}>Once</Select.Option>
                  </Select>
                </div>

                <div>
                  <label>Currency</label>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(currency: string) => {
                      let allCommissions = commissions;
                      allCommissions[index].currency = currency;
                      // @ts-ignore
                      setCommissions(allCommissions);
                    }}
                  >
                    <Select.Option value={"EUR"}>EUR</Select.Option>
                    <Select.Option value={"USD"}>USD</Select.Option>
                    <Select.Option value={"EGP"}>EGP</Select.Option>
                    <Select.Option value={"SAR"}>SAR</Select.Option>
                    <Select.Option value={"TRY"}>TRY</Select.Option>
                  </Select>
                </div>
                <div>
                  <label>Amount:</label>
                  <Input
                    type={"number"}
                    min={0}
                    onBlur={(e) => {
                      let allCommissions = commissions;
                      allCommissions[index].amount = Number(e.target.value);
                      // @ts-ignore
                      setCommissions(allCommissions);
                    }}
                  />
                </div>

                <div>
                  <label>Student joined at: </label>
                  <Space direction="vertical" size={12}>
                    <DatePicker
                      showTime
                      onOk={(moment) => {
                        let allCommissions = commissions;
                        allCommissions[index].joinedAt = moment.toDate();
                        // @ts-ignore
                        setCommissions(allCommissions);
                      }}
                    />
                  </Space>
                </div>
              </Tabs.TabPane>
            );
          })}
        </Tabs>

        <Row justify="center" align="middle" style={{ marginTop: 50 }}>
          <Form.Item
            style={{
              backgroundColor: "red",
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
