import React, { useEffect, useState } from "react";
import { Form, Modal, Select } from "antd";
import axios from "axios";
import os from "os";

type AddStudentToCourseModal = {
  isStudentsModalVisible: boolean;
  courseStudents: Array<any>;
  onOk: (students: Array<any>) => void;
  onCancel: () => void;
};
export const AddStudentToCourseModal = ({
  isStudentsModalVisible,
  courseStudents,
  onCancel,
  onOk,
}: AddStudentToCourseModal) => {
  const [allStudents, setAllStudents] = useState<Array<any>>([]);
  const [selectedStudents, setSelectedStudents] = useState<Array<any>>(
    courseStudents
  );
  const API = os?.hostname()?.includes("local")
    ? process.env.REACT_APP_DEV_ENDPOINT
    : process.env.REACT_APP_PROD_ENDPOINT;
  useEffect(() => {
    axios
      .get(API + "/students")
      .then((students) => {
        setAllStudents(students.data);
      })
      .catch((err) => {
        console.log("inside error", err);
      });
  }, []);

  const [form] = Form.useForm();

  return (
    <Modal
      title="Add student"
      visible={isStudentsModalVisible}
      onOk={(e) => {
        console.log("selectedStudents  OND --->>", selectedStudents);
        let newStudentsList = selectedStudents.map((student) => {
          if (typeof student === "string") {
            return allStudents.filter((std) => std.id === student)[0];
          } else {
            return student;
          }
        });

        form.submit();
        form.resetFields();
        onOk(newStudentsList);
      }}
      onCancel={(c) => {
        form.resetFields();
        onCancel();
      }}
    >
      <Form form={form}>
        <Form.Item name={"students"} label={"Select participated students"}>
          <Select
            showSearch
            mode={"multiple"}
            style={{ width: 200 }}
            defaultValue={selectedStudents.map((student) => student.id)}
            onSelect={(studentId) => {
              // @ts-ignore
              let stds = selectedStudents;
              stds.push(studentId);
              setSelectedStudents(stds);
            }}
            onDeselect={(deselectedStudentId) => {
              let stds = selectedStudents;
              stds = stds.filter((e) => e.id !== deselectedStudentId);
              setSelectedStudents(stds);
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {allStudents.map((student: any, index: number) => {
              return (
                <Select.Option value={student.id}>
                  {student.studentName + " " + student.fatherName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
