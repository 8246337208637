import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

export const SessionEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" title={"Name"} />
      <TextInput source="sessionPdfUrl" title={"Session PDF Url"} />
      <TextInput type={"number"} source="duration" title={"Duration"} />
      <TextInput source="summaryURL" title={"Summary Url"} />
    </SimpleForm>
  </Edit>
);
