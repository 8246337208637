import React from "react";
import { Datagrid, List, TextField } from "react-admin";

export const ContentsList = (props: any, record: any) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="contentCourseType" />
        <TextField source="contentType" />
      </Datagrid>
    </List>
  );
};
