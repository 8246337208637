import { useEditController, DateField } from "react-admin";
import React from "react";
import axios from "axios";
import { DatePicker, Button, Form, Input, Select, Tabs, Row } from "antd";
import { Chip, Link } from "@material-ui/core";
import { AddStudentToCourseModal } from "./components";
import moment from "moment";
import { AddSessionToCourseModal } from "./components/AddSessionToCourseModal";
import os from "os";

type CourseEditProps = {
  course: any;
  isStudentsModalVisible: boolean;
  isSessionsModalVisible: boolean;
  allSessions: Array<any>;
  allStudents: Array<any>;
  allInstructors: Array<any>;
};

const API = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;
export class CourseEdit extends React.Component<any, CourseEditProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      course: {},
      isStudentsModalVisible: false,
      isSessionsModalVisible: false,
      allSessions: [],
      allStudents: [],
      allInstructors: [],
    };
  }
  componentDidMount() {
    axios.get(API + "/courses/" + this.props.id).then((course) => {
      this.setState({ course: course.data });
    });
    axios
      .get(API + "/sessions")
      .then((sessions) => {
        this.setState({ allSessions: sessions.data });
      })
      .catch((err) => {
        console.log("inside error", err);
      });
    axios
      .get(API + "/students")
      .then((students) => {
        this.setState({ allStudents: students.data });
      })
      .catch((err) => {
        console.log("inside error", err);
      });
    axios
      .get(API + "/instructors")
      .then((instructors) => {
        this.setState({ allInstructors: instructors.data });
      })
      .catch((err) => {
        console.log("inside error", err);
      });
  }

  changeStudentModalStatus = () => {
    this.setState({
      isStudentsModalVisible: !this.state.isStudentsModalVisible,
    });
  };

  changeSessionModalStatus = () => {
    this.setState({
      isSessionsModalVisible: !this.state.isSessionsModalVisible,
    });
  };

  updateStudentsList = (newStudentsList: any) => {
    let course = this.state.course;

    course.students = newStudentsList;
    this.setState({
      isStudentsModalVisible: !this.state.isStudentsModalVisible,
      course: course,
    });
  };

  addSessionToCourse = (newSession: any) => {
    let course = this.state.course;
    course.sessions.push(newSession);
    this.setState({
      isSessionsModalVisible: !this.state.isSessionsModalVisible,
      course: course,
    });
  };

  render() {
    console.log();
    return (
      <div>
        {this.state.course?.id && (
          <Form
            onFinish={(data) => {
              //console.log("inside onFinish->", data);
              axios
                .put(
                  API + "/courses/" + this.state.course.id,
                  this.state.course
                )
                .then((res) => {
                  this.props.history.push("/courses");
                })
                .catch((err) => {
                  console.log("Inside cathc err ->", err);
                });
            }}
          >
            <Form.Item
              initialValue={this.state.course.id}
              style={{ width: "45%", display: "inline-block" }}
              label="Course id"
              name="id"
            >
              <Input disabled={true} />
            </Form.Item>

            <Form.Item
              name={"name"}
              label={"Course name"}
              rules={[
                {
                  required: true,
                  message: "Please select the course name!",
                },
              ]}
              initialValue={this.state.course.name}
            >
              <Select
                onSelect={(courseName) => {
                  let course = this.state.course;
                  course.name = courseName;
                  this.setState({ course: course });
                }}
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Select.Option value="English">English</Select.Option>
                <Select.Option value={"German"}>German</Select.Option>
                <Select.Option value={"Programming"}>Programming</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={"level"} label={"Course level"}>
              <Input
                onBlur={(e) => {
                  let course = this.state.course;
                  course.level = e.target.value;
                  this.setState({ course: course });
                }}
                defaultValue={this.state.course.level}
              />
            </Form.Item>
            <Form.Item
              initialValue={this.state.course.groupNumber}
              style={{ width: "45%", display: "inline-block" }}
              label="Group number"
              name="groupNumber"
            >
              <Input disabled={true} />
            </Form.Item>
            <h2>Students: </h2>
            {this.state.course.students.map((student: any, index: number) => {
              return (
                <Link href={"/students/" + student.id + "/show"}>
                  <Chip
                    style={{
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    label={student.studentName + " " + student.fatherName}
                  />
                </Link>
              );
            })}
            <Button onClick={() => this.changeStudentModalStatus()}>
              Add student
            </Button>
            {this.state.isStudentsModalVisible && (
              <AddStudentToCourseModal
                isStudentsModalVisible={this.state.isStudentsModalVisible}
                courseStudents={this.state.course.students}
                onOk={this.updateStudentsList}
                onCancel={this.changeStudentModalStatus}
              />
            )}
            <div>
              <label>Course created at: </label>
              <p>{new Date(this.state.course.createdAt).toDateString()}</p>
            </div>
            <Form.Item name={"courseStartedAt"} label={"Course started at"}>
              <DatePicker
                onChange={(e: any) => {
                  let course = this.state.course;
                  course.startedAt = new Date(e);
                  this.setState({ course: course });
                }}
                defaultValue={
                  this.state.course.startedAt &&
                  moment(this.state.course.startedAt)
                }
              />
            </Form.Item>
            <Form.Item name={"whatsappGroupId"} label={"Whatsapp Group Id"}>
              <Input
                onBlur={(e) => {
                  let course = this.state.course;
                  course.whatsappGroupId = e.target.value;
                  this.setState({ course: course });
                }}
                defaultValue={this.state.course.whatsappGroupId}
              />
            </Form.Item>

            <Form.Item name={"numberOfSessions"} label={"Number of sessions"}>
              <Input
                type={"number"}
                onBlur={(e) => {
                  let course = this.state.course;
                  course.numberOfSessions = e.target.value;
                  this.setState({ course: course });
                }}
                defaultValue={this.state.course.numberOfSessions}
              />
            </Form.Item>

            <h2>Sessions: </h2>
            <Button onClick={() => this.changeSessionModalStatus()}>
              Add session
            </Button>
            {this.state.isSessionsModalVisible && (
              <AddSessionToCourseModal
                isSessionsModalVisible={this.state.isSessionsModalVisible}
                onOk={this.addSessionToCourse}
                allInstructors={this.state.allInstructors}
                allStudents={this.state.allStudents}
                allSessions={this.state.allSessions}
                onCancel={() => {
                  console.log("cancel");
                }}
              />
            )}
            <Tabs
              defaultActiveKey="0"
              onChange={(e) => console.log("inside onChange->", e)}
            >
              {this.state.course.sessions.map((session: any, index: number) => {
                return (
                  <Tabs.TabPane
                    tab={"Sessions " + session.session.name}
                    key={index}
                  >
                    <div>
                      <label>Is session taught: </label>
                      <Link href={"/sessions/" + session.session.id + "/show"}>
                        <Chip
                          style={{
                            marginRight: "15px",
                            cursor: "pointer",
                            backgroundColor: session.taught ? "green" : "gray",
                          }}
                          label={session.taught ? "Yes" : "Not yet!"}
                        />
                      </Link>
                    </div>

                    <Form.Item name={"session"} label={"Session"}>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        defaultValue={session.session.id}
                        onSelect={(sessionId) => {
                          let newSession = this.state.allInstructors.filter(
                            (session) => session.id === sessionId
                          )[0];
                          let course = this.state.course;
                          course.sessions[index].session = newSession;
                          this.setState({ course: course });
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.state.allSessions.map(
                          (session: any, index: number) => {
                            /*console.log("allSessions - session->", session);
                            console.log(
                              "allSessions - session.name->",
                              session.name
                            );*/
                            return (
                              <Select.Option value={session.id}>
                                {session.name}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={"attendedStudents"}
                      label={"Attended students"}
                    >
                      <Select
                        showSearch
                        mode={"multiple"}
                        style={{ width: 200 }}
                        defaultValue={session.attendedStudents.map(
                          (student: any) => student.id
                        )}
                        onSelect={(studentId) => {
                          // @ts-ignore
                          let stds = session.attendedStudents;
                          stds.push(studentId);
                          let attendedStudentsList = session.attendedStudents.map(
                            (student: any) => {
                              if (typeof student === "string") {
                                return this.state.allStudents.filter(
                                  (std) => std.id === student
                                )[0];
                              } else {
                                return student;
                              }
                            }
                          );
                          let course = this.state.course;
                          course.sessions[
                            index
                          ].attendedStudents = attendedStudentsList;
                          this.setState({ course: course });
                        }}
                        onDeselect={(deselectedStudentId) => {
                          let attendedStudentsList = session.attendedStudents;
                          attendedStudentsList = attendedStudentsList.filter(
                            (e: any) => e.id !== deselectedStudentId
                          );
                          let course = this.state.course;
                          course.sessions[
                            index
                          ].attendedStudents = attendedStudentsList;
                          this.setState({ course: course });
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.state.allStudents.map(
                          (student: any, index: number) => {
                            /*console.log("allSessions - session->", student);
                            console.log(
                              "allSessions - session.name->",
                              student.studentName
                            );*/
                            return (
                              <Select.Option value={student.id}>
                                {student.studentName + " " + student.fatherName}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>

                    <Form.Item name={"sessionDate"} label={"Session date"}>
                      {console.log()}
                      <DatePicker
                        defaultValue={moment(session.date)}
                        onChange={(e: any) => {
                          let course = this.state.course;
                          course.sessions[index].date = new Date(e);
                          this.setState({ course: course });
                        }}
                      />
                    </Form.Item>

                    <Form.Item name={"instructors"} label={"Instructors"}>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        defaultValue={session.instructor.id}
                        onSelect={(instructorId) => {
                          let newInstructor = this.state.allInstructors.filter(
                            (insta) => insta.id === instructorId
                          )[0];
                          let course = this.state.course;
                          course.sessions[index].instructor = newInstructor;
                          this.setState({ course: course });
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.state.allInstructors.map(
                          (instructor: any, index: number) => {
                            return (
                              <Select.Option value={instructor.id}>
                                {instructor.name}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item name={"zoomUrl"} label={"zoomUrl"}>
                      <Input
                        onBlur={(e) => {
                          let course = this.state.course;
                          course.sessions[index].zoomUrl = e.target.value;
                          this.setState({ course: course });
                        }}
                        defaultValue={
                          session.zoomUrl ? session.zoomUrl : "None"
                        }
                      />
                    </Form.Item>
                  </Tabs.TabPane>
                );
              })}
            </Tabs>

            <Row justify="center" align="middle" style={{ marginTop: 50 }}>
              <Form.Item
                style={{
                  backgroundColor: "red",
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}
