import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";

export const SessionsShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="sessionPdfUrl" />
      <TextField source="duration" />
      <TextField source="summaryURL" />
    </SimpleShowLayout>
  </Show>
);
