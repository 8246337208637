import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  DateTimeInput,
  useCreateController,
  SaveButton,
  Title,
} from "react-admin";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Space,
} from "antd";
import axios from "axios";
import moment from "moment";
import os from "os";

type EditInstructorState = {
  instructor: {
    id: string;
    name: string;
    city: string;
    country: string;
    age: number;
    gender: "male" | "female" | "Not specified";
    canTeach: "English" | "German" | "Programming";
    /** When we prepare storage
         pictureURL: any;
         */
    hourRate: number;
    currency: "EUR" | "USD" | "EGP" | string;
    toPay: number;
    generatedMoney: number;
    totalPaid: number;
    //TODO: check it
    minWithdraw: number;
    createdAt: any;
    sessions: Array<{
      courseId: string;
      sessionId: string;
      taught: boolean;
      date: Date;
    }>;
    availableTimes?: Array<{ from: Date; to: Date }>;
    paymentMethod?: "Paypal" | "Bank transfer";
    paymentAccount?: string;
    paymentReference?: string;

    promoVideoURL?: string;
    parentApprovalFile?: string;
    //TODO: [{from:Date,to:Date,....}]
    //TODO: Research
    termsAndConditionApproval?: boolean;
  };
  allCourses: Array<any>;
  allSessions: Array<any>;
};

const API = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;
export class EditInstructor extends React.Component<any, EditInstructorState> {
  constructor(props: any) {
    super(props);
    this.state = {
      allSessions: [],
      allCourses: [],
      instructor: {
        id: "",
        name: "",
        city: "",
        country: "",
        age: 0,
        gender: "male",
        canTeach: "English",
        hourRate: 4,
        currency: "USD",
        toPay: 0,
        generatedMoney: 0,
        totalPaid: 0,
        minWithdraw: 100,
        createdAt: new Date(),
        sessions: [],
        availableTimes: [{ from: new Date(), to: new Date() }],
        paymentMethod: "Paypal",
        paymentAccount: "",
        paymentReference: "",
        promoVideoURL: "",
      },
    };
  }
  componentDidMount() {
    axios
      .get(API + "/instructors/" + this.props.id)
      .then((insta) => {
        let instructor = insta.data;
        instructor.id = this.props.id;
        this.setState({ instructor: instructor });
      })
      .catch((err) => {
        console.log("inside error", err);
      });

    axios
      .get(API + "/sessions")
      .then((sessions) => {
        this.setState({ allSessions: sessions.data });
      })
      .catch((err) => {
        console.log("inside error", err);
      });

    axios
      .get(API + "/courses")
      .then((course) => {
        this.setState({ allCourses: course.data });
      })
      .catch((err) => {
        console.log("inside error", err);
      });
  }

  render() {
    return (
      <div>
        <Title title={"Create instructor"} />
        {this.state.instructor.name.length !== 0 && (
          <Form
            name="basic"
            onFinish={(formData) => {
              axios
                .put(
                  API + "/instructors/" + this.state.instructor.id,
                  this.state.instructor
                )
                .then((re) => {
                  console.log("Creted ->", re);
                  this.props.history.push("/instructors");
                })
                .catch((err) => {
                  console.log("inside error->", err);
                });
            }}
          >
            <Form.Item
              initialValue={this.state.instructor.name}
              style={{ width: "45%", display: "inline-block" }}
              label="Instructor full name"
              name="name"
              rules={[
                { required: true, message: "Please enter Instructor name!" },
              ]}
            >
              <Input
                defaultValue={this.state.instructor.name}
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.name = e.target.value;
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>

            <Form.Item
              initialValue={this.state.instructor.country}
              style={{ width: "45%", display: "inline-block" }}
              label="Country"
              name="country"
              rules={[{ required: true, message: "Please enter Country" }]}
            >
              <Input
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.country = e.target.value;
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>

            <Form.Item
              initialValue={this.state.instructor.city}
              style={{ width: "45%", display: "inline-block" }}
              label="City"
              name="city"
              rules={[{ required: true, message: "Please enter City!" }]}
            >
              <Input
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.city = e.target.value;
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>

            <Form.Item
              initialValue={this.state.instructor.age}
              style={{ width: "45%", display: "inline-block" }}
              label="Age"
              name="age"
              rules={[{ required: true, message: "Please enter Age!" }]}
            >
              <Input
                type={"number"}
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.age = parseInt(e.target.value);
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>

            <Form.Item
              initialValue={this.state.instructor.gender}
              name={"gender"}
              label={"Gender"}
              rules={[
                {
                  required: true,
                  message: "Please select the gender!",
                },
              ]}
            >
              <Select
                onSelect={(selectedStatus) => {
                  let inst = this.state.instructor;
                  // @ts-ignore
                  inst.gender = selectedStatus;
                  this.setState({ instructor: inst });
                }}
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value={"Female"}>Female</Select.Option>
                <Select.Option value={"Not specified"}>
                  Not specified
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              initialValue={this.state.instructor.canTeach}
              name={"canTeach"}
              label={"Can teach"}
              rules={[
                {
                  required: true,
                  message: "Please select the canTeach!",
                },
              ]}
            >
              <Select
                onSelect={(selectedStatus) => {
                  let inst = this.state.instructor;
                  // @ts-ignore
                  inst.canTeach = selectedStatus;
                  this.setState({ instructor: inst });
                }}
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Select.Option value={"English"}>English</Select.Option>
                <Select.Option value={"German"}>German</Select.Option>
                <Select.Option value={"Programming"}>Programming</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              initialValue={this.state.instructor.hourRate}
              style={{ width: "45%", display: "inline-block" }}
              label="Hour rate"
              name="hourRate"
              rules={[{ required: true, message: "Please enter Hour rate!" }]}
            >
              <Input
                type={"number"}
                min={0}
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.hourRate = parseInt(e.target.value);
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>

            <Form.Item
              initialValue={this.state.instructor.currency}
              name={"currency"}
              label={"Currency"}
              rules={[
                {
                  required: true,
                  message: "Please select the Currency!",
                },
              ]}
            >
              <Select
                onSelect={(selectedStatus) => {
                  let inst = this.state.instructor;
                  // @ts-ignore
                  inst.currency = selectedStatus;
                  this.setState({ instructor: inst });
                }}
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value={"USD"}>USD</Select.Option>
                <Select.Option value={"EGP"}>EGP</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{ width: "45%", display: "inline-block" }}
              label="To pay"
              name="toPay"
              initialValue={this.state.instructor.toPay}
            >
              <Input
                type={"number"}
                min={0}
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.toPay = parseInt(e.target.value);
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>
            <Form.Item
              style={{ width: "45%", display: "inline-block" }}
              label="Generated money"
              name="generatedMoney"
              initialValue={this.state.instructor.generatedMoney}
              rules={[
                { required: true, message: "Please enter generatedMoney!" },
              ]}
            >
              <Input
                type={"number"}
                min={0}
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.generatedMoney = parseInt(e.target.value);
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>
            <Form.Item
              style={{ width: "45%", display: "inline-block" }}
              label="Total paid"
              name="totalPaid"
              initialValue={this.state.instructor.totalPaid}
              rules={[{ required: true, message: "Please enter totalPaid!" }]}
            >
              <Input
                type={"number"}
                min={0}
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.totalPaid = parseInt(e.target.value);
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>

            <Form.Item
              style={{ width: "45%", display: "inline-block" }}
              label="Min. withdraw"
              name="minWithdraw"
              rules={[{ required: true, message: "Please enter totalPaid!" }]}
              initialValue={this.state.instructor.minWithdraw}
            >
              <Input
                type={"number"}
                min={100}
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.minWithdraw = parseInt(e.target.value);
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>

            <Form.Item
              name={"paymentMethod"}
              label={"Payment method"}
              initialValue={this.state.instructor.paymentMethod}
            >
              <Select
                onSelect={(selectedStatus) => {
                  let inst = this.state.instructor;
                  // @ts-ignore
                  inst.paymentMethod = selectedStatus;
                  this.setState({ instructor: inst });
                }}
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Select.Option value="Paypal">Paypal</Select.Option>
                <Select.Option value={"Bank transfer"}>
                  Bank transfer
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{ width: "45%", display: "inline-block" }}
              label="Payment account"
              name="paymentAccount"
              initialValue={this.state.instructor.paymentAccount}
            >
              <Input
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.paymentAccount = e.target.value;
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>

            <Form.Item
              initialValue={this.state.instructor.paymentReference}
              style={{ width: "45%", display: "inline-block" }}
              label="Payment reference"
              name="paymentReference"
            >
              <Input
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.paymentReference = e.target.value;
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>
            <Form.Item
              style={{ width: "45%", display: "inline-block" }}
              label="Promo url"
              name="promoVideoURL"
              initialValue={this.state.instructor.promoVideoURL}
            >
              <Input
                onBlur={(e) => {
                  let inst = this.state.instructor;
                  inst.promoVideoURL = e.target.value;
                  this.setState({ instructor: inst });
                }}
              />
            </Form.Item>
            <div>
              <h2>Available times</h2>
              <Button
                onClick={() => {
                  let insta = this.state.instructor;
                  // @ts-ignore
                  insta.availableTimes.push({
                    from: new Date(),
                    to: new Date(),
                  });
                  this.setState({ instructor: insta });
                }}
              >
                Add new Available time
              </Button>
            </div>
            <Space direction="vertical" size={12}>
              {this.state.instructor.availableTimes?.map(
                (time: any, index: number) => {
                  console.log("time ->", new Date(time.from));
                  return (
                    <DatePicker.RangePicker
                      value={[
                        moment(new Date(time.from)),
                        moment(new Date(time.to)),
                      ]}
                      onChange={(moments: any) => {
                        let insta = this.state.instructor;
                        // @ts-ignore
                        insta.availableTimes[index] = {
                          from: new Date(moments[0]),
                          to: new Date(moments[0]),
                        };
                        this.setState({ instructor: insta });
                      }}
                      showTime={{
                        hideDisabledOptions: true,
                      }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  );
                }
              )}
            </Space>
            <h2>Sessions:</h2>
            <Button
              onClick={() => {
                let insta = this.state.instructor;
                insta.sessions.push({
                  courseId: "",
                  sessionId: "",
                  taught: false,
                  date: new Date(),
                });
                this.setState({ instructor: insta });
              }}
            >
              Add session
            </Button>
            <Tabs
              defaultActiveKey="0"
              onChange={(e) => console.log("inside onChange->", e)}
            >
              {this.state.instructor.sessions?.map(
                (session: any, index: number) => {
                  console.log("insta ->", session.sessionId);
                  return (
                    <Tabs.TabPane tab={"Session " + index} key={index}>
                      <label>Select session: </label>
                      <Select
                        defaultValue={session.sessionId}
                        showSearch
                        style={{ width: 200 }}
                        onSelect={(sessionId) => {
                          let insta = this.state.instructor;
                          // @ts-ignore
                          insta.sessions[index].sessionId = sessionId;
                          this.setState({ instructor: insta });
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.state.allSessions.map(
                          (session: any, index: number) => {
                            console.log("session.id from lll->", session.id);
                            return (
                              <Select.Option value={session.id}>
                                {session.name}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>

                      <div>
                        <label>Select course</label>
                        <Select
                          defaultValue={session.courseId}
                          showSearch
                          style={{ width: 200 }}
                          onSelect={(courseId) => {
                            let insta = this.state.instructor;
                            // @ts-ignore
                            insta.sessions[index].courseId = courseId;
                            this.setState({ instructor: insta });
                          }}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {this.state.allCourses.map(
                            (course: any, index: number) => {
                              return (
                                <Select.Option value={course.id}>
                                  {course.name}- level-{course.level}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      </div>
                      <div>
                        <label>Is session taught? </label>
                        <Select
                          defaultValue={session.taught ? "Yes" : "No"}
                          showSearch
                          style={{ width: 200 }}
                          /*defaultValue={session.session.id}*/
                          onSelect={(taughtId) => {
                            let insta = this.state.instructor;
                            // @ts-ignore
                            insta.sessions[index].taught = taughtId === "Yes";
                            this.setState({ instructor: insta });
                          }}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Select.Option value={"Yes"}>Yes</Select.Option>
                          <Select.Option value={"No"}>Not yet!</Select.Option>
                        </Select>
                      </div>
                      <div>
                        <label>Date: </label>
                        <DatePicker
                          defaultValue={moment(new Date())}
                          onChange={(e: any) => {
                            let insta = this.state.instructor;
                            // @ts-ignore
                            insta.sessions[index].date = new Date(e);
                            this.setState({ instructor: insta });
                          }}
                        />
                      </div>
                    </Tabs.TabPane>
                  );
                }
              )}
            </Tabs>
            <Row justify="center" align="middle" style={{ marginTop: 50 }}>
              <Form.Item
                style={{
                  backgroundColor: "red",
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}
