import React from "react";
import axios from "axios";
import { Button, Form, Input, Row, Select, Tabs } from "antd";
import { Chip, Link } from "@material-ui/core";
import os from "os";

type EditStudentState = {
  student: any;
  studentCourses: Array<{
    courseId: string;
    amount: number;
    currency: string;
    paymentMethod: string;
    paymentReceiver: string;
    status: "Paid" | "Pending" | "Potential";
    studentLevel: number;
  }>;
};

const API = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;

export class EditStudent extends React.Component<any, EditStudentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      student: undefined,
      studentCourses: [],
    };
  }
  //  controllerProps = useEditController(props);
  componentDidMount() {
    axios
      .get(API + "/students/" + this.props.id)
      .then((student) => {
        let studentData = student.data;
        let courses = studentData.courses.map((course: any) => {
          return {
            courseId: course.course.id,
            amount: course.amount,
            currency: course.currency,
            paymentMethod: course.paymentMethod,
            paymentReceiver: course.paymentReceiver,
            status: course.status,
            studentLevel: course.studentLevel,
          };
        });
        this.setState({ student: studentData, studentCourses: courses });
      })
      .catch((err) => {
        console.log("inside error ->", err);
      });
  }

  render() {
    return (
      <div>
        {this.state.student?.id && (
          <Form
            onFinish={(formData) => {
              console.log(
                "formData inside studentCourses->",
                this.state.studentCourses
              );
              formData.courses = this.state.studentCourses;

              axios
                .put(API + "/students/" + this.props.id, formData)
                .then((res) => {
                  console.log("student from get ->", res);
                  this.props.history.push("/students");
                })
                .catch((err) => {
                  console.log("inside error ->", err);
                });
            }}
          >
            <div>
              <Form.Item
                initialValue={this.state.student.studentName}
                style={{ width: "45%", display: "inline-block" }}
                label="Student name"
                name="studentName"
                rules={[
                  { required: true, message: "Please enter student name!" },
                ]}
              >
                <Input
                  onChange={(e) => {
                    console.log("e->", e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                style={{
                  width: "45%",
                  display: "inline-block",
                  float: "right",
                }}
                label="Father name"
                name="fatherName"
                rules={[
                  { required: true, message: "Please enter father name!" },
                ]}
                initialValue={this.state.student.fatherName}
              >
                <Input />
              </Form.Item>
              <Form.Item
                style={{ width: "45%", display: "inline-block" }}
                label="Mother name"
                name="motherName"
                initialValue={
                  this.state.student.motherName
                    ? this.state.student.motherName
                    : ""
                }
              >
                <Input />
              </Form.Item>

              <Form.Item
                style={{
                  width: "45%",
                  display: "inline-block",
                  float: "right",
                }}
                label="Age"
                name="age"
                rules={[
                  {
                    required: true,
                    message: "Please enter student age!",
                  },
                ]}
                initialValue={this.state.student.age}
              >
                <Input type={"number"} min={0} />
              </Form.Item>

              <Form.Item
                name={"gender"}
                label={"Gender"}
                rules={[
                  {
                    required: true,
                    message: "Please select the student gender!",
                  },
                ]}
                initialValue={this.state.student.gender}
              >
                <Select
                  onSelect={(selectedStatus) => {
                    console.log("selectedStatus->", selectedStatus);
                    // @ts-ignore
                    //newCourse.gender = selectedStatus;
                  }}
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  <Select.Option value="Male">Male</Select.Option>
                  <Select.Option value={"Female"}>Female</Select.Option>
                  <Select.Option value={"Not specified"}>
                    Not specified
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                style={{
                  width: "45%",
                  display: "inline-block",
                  float: "right",
                }}
                label="Country"
                name="country"
                rules={[
                  { required: true, message: "Please enter country name!" },
                ]}
                initialValue={this.state.student.country}
              >
                <Input />
              </Form.Item>

              <Form.Item
                style={{ width: "45%", display: "inline-block" }}
                label="Primary whatsApp number"
                name="whatsappNumber1"
                rules={[
                  {
                    required: true,
                    message: "Please enter primary whatsApp number!",
                  },
                ]}
                initialValue={this.state.student.whatsappNumber1}
              >
                <Input />
              </Form.Item>
              <Form.Item
                style={{ width: "45%", display: "inline-block" }}
                label="Secondary whatsApp number"
                name="whatsappNumber2"
                initialValue={
                  this.state.student.whatsappNumber2
                    ? this.state.student.whatsappNumber2
                    : ""
                }
              >
                <Input />
              </Form.Item>

              <Form.Item
                style={{
                  width: "45%",
                  display: "inline-block",
                  float: "right",
                }}
                label="parent facebook profile"
                name="parentFacebookProfile"
                initialValue={
                  this.state.student.parentFacebookProfile
                    ? this.state.student.parentFacebookProfile
                    : ""
                }
              >
                <Input />
              </Form.Item>

              <Tabs
                defaultActiveKey="0"
                onChange={(e) => console.log("inside onChange->", e)}
              >
                {this.state.student.courses.map(
                  (course: any, index: number) => {
                    return (
                      <Tabs.TabPane
                        tab={"Course " + course.course.name}
                        key={index}
                      >
                        <div
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <label>Student level: </label>
                          <Input
                            defaultValue={course.studentLevel}
                            onBlur={(e) => {
                              let studentCourses = this.state.studentCourses;
                              studentCourses[index].studentLevel = parseInt(
                                e.target.value
                              );
                              this.setState({ studentCourses: studentCourses });
                            }}
                            type={"number"}
                            min={0}
                          />
                        </div>

                        <div
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <label>Price: </label>
                          <Input
                            defaultValue={course.amount}
                            onBlur={(e) => {
                              let studentCourses = this.state.studentCourses;
                              studentCourses[index].amount = parseInt(
                                e.target.value
                              );
                              this.setState({ studentCourses: studentCourses });
                            }}
                            type={"number"}
                            min={0}
                          />
                        </div>

                        <div
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <label>Currency: </label>
                          <Select
                            defaultValue={course.currency}
                            onSelect={(e) => {
                              let studentCourses = this.state.studentCourses;
                              studentCourses[index].currency = e.toString();
                              this.setState({ studentCourses: studentCourses });
                            }}
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            <Select.Option value="EUR">EUR</Select.Option>
                            <Select.Option value={"USD"}>USD</Select.Option>
                            <Select.Option value={"EGP"}>EGP</Select.Option>
                            <Select.Option value={"SAR"}>SAR</Select.Option>
                          </Select>
                        </div>
                        <div
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <label>Payment receiver: </label>
                          <Input
                            defaultValue={course.paymentReceiver}
                            onBlur={(e) => {
                              let studentCourses = this.state.studentCourses;
                              studentCourses[index].paymentReceiver =
                                e.target.value;
                              this.setState({ studentCourses: studentCourses });
                            }}
                          />
                        </div>
                        <div
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <label>Payment method: </label>
                          <Select
                            defaultValue={course.paymentMethod}
                            onSelect={(e) => {
                              let studentCourses = this.state.studentCourses;
                              studentCourses[
                                index
                              ].paymentMethod = e.toString();
                              this.setState({ studentCourses: studentCourses });
                            }}
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            <Select.Option value="Paypal">Paypal</Select.Option>
                            <Select.Option value={"Bank transfer"}>
                              Bank transfer
                            </Select.Option>
                            <Select.Option value={"Vodafone"}>
                              Vodafone
                            </Select.Option>
                          </Select>
                        </div>

                        <div
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <label>Status: </label>
                          <Select
                            defaultValue={course.status}
                            onSelect={(e) => {
                              let studentCourses = this.state.studentCourses;
                              // @ts-ignore
                              studentCourses[index].status = e;
                              this.setState({ studentCourses: studentCourses });
                            }}
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            <Select.Option value="Paid">Paid</Select.Option>
                            <Select.Option value={"Pending"}>
                              Pending
                            </Select.Option>
                            <Select.Option value={"Potential"}>
                              Potential
                            </Select.Option>
                          </Select>
                        </div>

                        {course.course.sessions.map((session: any) => {
                          console.log("session->", session);
                          return (
                            <div
                              style={{
                                display: "inline-block",
                                border: "1px solid lightGray",
                                margin: "10px",
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              <Link
                                href={
                                  "/sessions/" + session.session.id + "/show"
                                }
                              >
                                <Chip
                                  style={{
                                    backgroundColor:
                                      new Date() <
                                      new Date(session.date._seconds * 1000)
                                        ? "gray"
                                        : session.attendedStudentsIds.includes(
                                            this.state.student.id
                                          )
                                        ? "green"
                                        : "red",
                                    marginRight: "15px",
                                    cursor: "pointer",
                                  }}
                                  label={session.session.name}
                                />
                              </Link>
                              <div>
                                <text style={{ color: "gray" }}>
                                  Session Date:
                                </text>
                                <br />
                                <text>
                                  {new Date(
                                    session.date._seconds * 1000
                                  ).toDateString()}
                                </text>
                              </div>
                            </div>
                          );
                        })}
                      </Tabs.TabPane>
                    );
                  }
                )}
              </Tabs>
            </div>
            <Row justify="center" align="middle" style={{ marginTop: 50 }}>
              <Form.Item
                style={{
                  backgroundColor: "red",
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}
