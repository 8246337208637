import React from "react";
import { List, Datagrid, TextField } from "react-admin";

export const CommissionsList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" label={"Name"} />
      <TextField source="generatedMoney" label={"Generated money"} />
      <TextField source="toPay" label={"To pay"} />
      <TextField source="paid" label={"Total paid"} />
    </Datagrid>
  </List>
);
