import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  DateTimeInput,
  useCreateController,
  SaveButton,
  Title,
} from "react-admin";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Space,
} from "antd";
import axios from "axios";
import moment from "moment";
import { PaymentMethodTypes } from "../../types";
import os from "os";

type CreateInstructorState = {
  instructor: {
    name: string;
    fatherName: string;
    city: string;
    country: string;
    age: number;
    gender: "male" | "female" | "Not specified";
    canTeach: "English" | "German" | "Programming";
    pictureURL: string;
    hourRate: number;
    currency: "EUR" | "USD" | "EGP" | string;
    toPay: number;
    generatedMoney: number;
    totalPaid: number;
    //TODO: check it
    minWithdraw: number;
    joinedAt: Date;
    sessions: Array<{
      courseId: string;
      sessionId: string;
      taught: boolean;
      date: Date;
    }>;
    availableTimes: Array<{ from: Date; to: Date }> | [];
    paymentMethod?: PaymentMethodTypes;
    paymentAccount?: string;
    paymentReference?: string;

    promoVideoURL?: string;
    parentApprovalFile?: string;
    //TODO: [{from:Date,to:Date,....}]
    //TODO: Research
    termsAndConditionApproval?: boolean;
  };
  allGroups: Array<any>;
};

const API = os?.hostname()?.includes("local")
  ? process.env.REACT_APP_DEV_ENDPOINT
  : process.env.REACT_APP_PROD_ENDPOINT;
export class CreateInstructor extends React.Component<
  any,
  CreateInstructorState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      allGroups: [],

      instructor: {
        name: "",
        fatherName: "",
        city: "",
        country: "",
        age: 0,
        gender: "male",
        canTeach: "English",
        pictureURL: "",
        hourRate: 4,
        currency: "USD",
        toPay: 0,
        generatedMoney: 0,
        totalPaid: 0,
        minWithdraw: 100,
        joinedAt: new Date(),
        availableTimes: [],
        sessions: [],
        paymentMethod: "Paypal",
        paymentAccount: "",
        paymentReference: "",
        promoVideoURL: "",
      },
    };
  }
  componentDidMount() {
    axios
      .get(API + "/groups")
      .then((groups) => {
        this.setState({ allGroups: groups.data });
      })
      .catch((err) => {
        console.log("inside error", err);
      });
  }

  render() {
    return (
      <div>
        <Title title={"Create instructor"} />
        <Form
          name="basic"
          onFinish={(formData) => {
            axios
              .post(API + "/instructors", this.state.instructor)
              .then((re) => {
                console.log("Creted ->", re);
                this.props.history.push("/instructors");
              })
              .catch((err) => {
                console.log("inside error->", err);
              });
          }}
        >
          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Instructor full name"
            name="name"
            rules={[
              { required: true, message: "Please enter Instructor name!" },
            ]}
          >
            <Input
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.name = e.target.value;
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Instructor father name"
            name="fatherName"
            rules={[
              {
                required: true,
                message: "Please enter Instructor's father name!",
              },
            ]}
          >
            <Input
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.fatherName = e.target.value;
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Country"
            name="country"
            rules={[{ required: true, message: "Please enter Country" }]}
          >
            <Input
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.country = e.target.value;
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="City"
            name="city"
            rules={[{ required: true, message: "Please enter City!" }]}
          >
            <Input
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.city = e.target.value;
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Age"
            name="age"
            rules={[{ required: true, message: "Please enter Age!" }]}
          >
            <Input
              type={"number"}
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.age = parseInt(e.target.value);
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            name={"gender"}
            label={"Gender"}
            rules={[
              {
                required: true,
                message: "Please select the gender!",
              },
            ]}
          >
            <Select
              onSelect={(selectedStatus) => {
                let inst = this.state.instructor;
                // @ts-ignore
                inst.gender = selectedStatus;
                this.setState({ instructor: inst });
              }}
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value={"Female"}>Female</Select.Option>
              <Select.Option value={"Not specified"}>
                Not specified
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={"canTeach"}
            label={"Can teach"}
            rules={[
              {
                required: true,
                message: "Please select the canTeach!",
              },
            ]}
          >
            <Select
              onSelect={(selectedStatus) => {
                let inst = this.state.instructor;
                // @ts-ignore
                inst.canTeach = selectedStatus;
                this.setState({ instructor: inst });
              }}
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Select.Option value={"English"}>English</Select.Option>
              <Select.Option value={"German"}>German</Select.Option>
              <Select.Option value={"Programming"}>Programming</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Hour rate"
            name="hourRate"
            rules={[{ required: true, message: "Please enter Hour rate!" }]}
          >
            <Input
              type={"number"}
              min={0}
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.hourRate = parseInt(e.target.value);
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            name={"currency"}
            label={"Currency"}
            rules={[
              {
                required: true,
                message: "Please select the Currency!",
              },
            ]}
          >
            <Select
              onSelect={(selectedStatus) => {
                let inst = this.state.instructor;
                // @ts-ignore
                inst.currency = selectedStatus;
                this.setState({ instructor: inst });
              }}
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value={"USD"}>USD</Select.Option>
              <Select.Option value={"EGP"}>EGP</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="To pay"
            name="toPay"
            initialValue={this.state.instructor.toPay}
          >
            <Input
              type={"number"}
              min={0}
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.toPay = parseInt(e.target.value);
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Generated money"
            name="generatedMoney"
            initialValue={this.state.instructor.generatedMoney}
            rules={[
              { required: true, message: "Please enter generatedMoney!" },
            ]}
          >
            <Input
              type={"number"}
              min={0}
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.generatedMoney = parseInt(e.target.value);
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Total paid"
            name="totalPaid"
            initialValue={this.state.instructor.totalPaid}
            rules={[{ required: true, message: "Please enter totalPaid!" }]}
          >
            <Input
              type={"number"}
              min={0}
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.totalPaid = parseInt(e.target.value);
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Min. withdraw"
            name="minWithdraw"
            rules={[{ required: true, message: "Please enter totalPaid!" }]}
            initialValue={this.state.instructor.minWithdraw}
          >
            <Input
              type={"number"}
              min={100}
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.minWithdraw = parseInt(e.target.value);
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Joined at"
            name="joinedAt"
          >
            <Space direction="vertical">
              <DatePicker
                onChange={(e) => {
                  let inst = this.state.instructor;
                  // @ts-ignore
                  inst.joinedAt = e.toDate();
                  this.setState({ instructor: inst });
                }}
              />
            </Space>
          </Form.Item>

          <Form.Item name={"paymentMethod"} label={"Payment method"}>
            <Select
              onSelect={(selectedStatus) => {
                let inst = this.state.instructor;
                // @ts-ignore
                inst.paymentMethod = selectedStatus;
                this.setState({ instructor: inst });
              }}
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Select.Option value="Paypal">Paypal</Select.Option>
              <Select.Option value={"Bank transfer"}>
                Bank transfer
              </Select.Option>
              <Select.Option value="Vodafone cash">Vodafone cash</Select.Option>
              <Select.Option value="Payoneer">Payoneer</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Payment account"
            name="paymentAccount"
          >
            <Input
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.paymentAccount = e.target.value;
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Payment reference"
            name="paymentReference"
          >
            <Input
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.paymentReference = e.target.value;
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Promo url"
            name="promoVideoURL"
          >
            <Input
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.promoVideoURL = e.target.value;
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "45%", display: "inline-block" }}
            label="Profile picture"
            name="pictureURL"
          >
            <Input
              onBlur={(e) => {
                let inst = this.state.instructor;
                inst.pictureURL = e.target.value;
                this.setState({ instructor: inst });
              }}
            />
          </Form.Item>

          <div>
            <h2>Available times</h2>
            <Button
              onClick={() => {
                let insta = this.state.instructor;

                // @ts-ignore

                insta.availableTimes.push({
                  // @ts-ignore
                  from: new Date(),
                  // @ts-ignore
                  to: new Date(),
                });
                this.setState({ instructor: insta });
              }}
            >
              Add new Available time
            </Button>
          </div>
          <Space direction="vertical" size={12}>
            {/*// @ts-ignore*/}
            {this.state.instructor.availableTimes?.map(
              (time: any, index: number) => {
                return (
                  <DatePicker.RangePicker
                    onChange={(moments: any) => {
                      let insta = this.state.instructor;

                      // @ts-ignore
                      insta.availableTimes[index] = {
                        from: new Date(moments[0]),
                        to: new Date(moments[0]),
                      };
                      this.setState({ instructor: insta });
                    }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment({
                          year: time.from.getFullYear(),
                          month: time.from.getMonth() + 1,
                          day: time.from.getDate(),
                          hour: time.from.getHours(),
                          minute: time.from.getMinutes(),
                        }),
                        moment({
                          year: time.to.getFullYear(),
                          month: time.to.getMonth() + 1,
                          day: time.to.getDate(),
                          hour: time.to.getHours(),
                          minute: time.to.getMinutes(),
                        }),
                      ],
                    }}
                    format="YYYY-MM-DD HH:mm"
                  />
                );
              }
            )}
          </Space>
          <h2>Sessions:</h2>
          <Button
            onClick={() => {
              let insta = this.state.instructor;
              insta.sessions.push({
                courseId: "",
                sessionId: "",
                taught: false,
                date: new Date(),
              });
              this.setState({ instructor: insta });
            }}
          >
            Add session
          </Button>
          <Tabs
            defaultActiveKey="0"
            onChange={(e) => console.log("inside onChange->", e)}
          >
            {this.state.instructor.sessions?.map(
              (session: any, index: number) => {
                return (
                  <Tabs.TabPane tab={"Session " + index} key={index}>
                    <label>Select Group: </label>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      onSelect={(sessionId) => {
                        let insta = this.state.instructor;
                        // @ts-ignore
                        insta.sessions[index].sessionId = sessionId;
                        this.setState({ instructor: insta });
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {this.state.allGroups.map((group: any, index: number) => {
                        return (
                          <Select.Option value={group.id}>
                            {group.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Tabs.TabPane>
                );
              }
            )}
          </Tabs>
          <Row justify="center" align="middle" style={{ marginTop: 50 }}>
            <Form.Item
              style={{
                backgroundColor: "red",
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
  }
}
