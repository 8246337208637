import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Select } from "antd";
import { useRedirect } from "react-admin";
import axios from "axios";
import { Group, Student } from "../../types";
import os from "os";

export const CreatePayment = (props: any) => {
  const [allStudents, setAllStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [studentGroups, setStudentGroups] = useState([]);
  const redirect = useRedirect();
  const API = os?.hostname()?.includes("local")
    ? process.env.REACT_APP_DEV_ENDPOINT
    : process.env.REACT_APP_PROD_ENDPOINT;
  useEffect(() => {
    axios.get(API + "/students").then((students) => {
      setAllStudents(students.data);
    });
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (selectedStudent?.groups) {
      // @ts-ignore
      Promise.all(
        // @ts-ignore
        selectedStudent.groups?.map(async (studentGroup: any) => {
          let group = await axios.get(
            API + "/groups/object/" + studentGroup.groupId
          );

          return group.data;
        })
      ).then((groups: any) => {
        setStudentGroups(groups);
      });
    }

    //getStudentGroups();
  }, [selectedStudent]);

  return (
    <div>
      <Form
        name="basic"
        onFinish={(formData) => {
          let payment = formData;
          payment.student = allStudents.filter(
            (student: Student) => student.id === formData.student
          )[0];
          payment.group = studentGroups.filter(
            (group: Group) => group.id === formData.studentGroup
          )[0];
          delete formData.studentGroup;

          axios
            .post(API + "/payments", payment)
            .then(() => {
              redirect("/payments");
            })
            .catch((err) => {
              console.log("inside error->", err);
            });
        }}
      >
        <Form.Item
          name={"student"}
          label={"Student"}
          rules={[
            {
              required: true,
              message: "Please select the student gender!",
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            onChange={(selectedStudentId: string) => {
              let selectedStd = allStudents.filter(
                (student: Student) => student.id === selectedStudentId
              )[0];
              setSelectedStudent(selectedStd);
            }}
          >
            {allStudents.map((student: Student, index: number) => {
              return (
                <Select.Option key={student.id} value={student.id}>
                  {student.studentName}-{student.fatherName}-
                  {student.motherName && student.motherName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name={"studentGroup"}
          label={"Student groups"}
          rules={[
            {
              required: true,
              message: "Please select the student gender!",
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            onChange={(selectedStudentId: string) => {
              let selectedStd = allStudents.filter(
                (student: Student) => student.id === selectedStudentId
              )[0];
              setSelectedStudent(selectedStd);
            }}
          >
            {studentGroups?.map((studentGroup: any, index: number) => {
              console.log();
              return (
                <Select.Option key={studentGroup.id} value={studentGroup.id}>
                  {studentGroup.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name={"currency"}
          label={"Currency"}
          rules={[
            {
              required: true,
              message: "Please select the Currency!",
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Select.Option value={"EUR"}>EUR</Select.Option>
            <Select.Option value={"USD"}>USD</Select.Option>
            <Select.Option value={"EGP"}>EGP</Select.Option>
            <Select.Option value={"SAR"}>SAR</Select.Option>
            <Select.Option value={"TRY"}>TRY</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name={"status"}
          label={"Payment status"}
          rules={[
            {
              required: true,
              message: "Please select the payment status!",
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Select.Option value={"Paid"}>Paid</Select.Option>
            <Select.Option value={"Pending"}>Pending</Select.Option>
            <Select.Option value={"Canceled"}>Canceled</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Payment amount"
          name="amount"
          rules={[{ required: true, message: "Please enter payment amount!" }]}
        >
          <Input type={"number"} />
        </Form.Item>

        <Form.Item
          style={{ width: "45%", display: "inline-block" }}
          label="Payment receiver"
          name="receiver"
          rules={[{ required: true, message: "Please enter payment amount!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"paymentMethod"}
          label={"Payment method"}
          rules={[
            {
              required: true,
              message: "Please select the payment method!",
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Select.Option value={"Paypal"}>Paypal</Select.Option>
            <Select.Option value={"Bank transfer"}>Bank transfer</Select.Option>
            <Select.Option value={"Vodafone cash"}>Vodafone cash</Select.Option>
            <Select.Option value={"Payoneer"}>Payoneer</Select.Option>
          </Select>
        </Form.Item>
        <Row justify="center" align="middle" style={{ marginTop: 50 }}>
          <Form.Item
            style={{
              backgroundColor: "red",
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
